import React, { useMemo, useState } from 'react';
import { GetCallDetailResponse } from '@distribute/shared/api-types/call';
import { Dropdown, Icon, Tooltip, Avatar } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { formatTranscriptTime, getTranscriptText } from '../lib';
import { downloadFile } from '../../../shared/lib';
import { analytics } from '../../../entities/analytics';
import { AnalyticsEvents } from '@distribute/shared/types';

type CallTranscriptProps = {
  call: GetCallDetailResponse;
};

const DEFAULT_SPEAKER_ID = 'all';

export const CallTranscript: React.FC<CallTranscriptProps> = ({ call }) => {
  const [selectedSpeakerId, setSelectedSpeakerId] =
    useState<string>(DEFAULT_SPEAKER_ID);
  const [copied, setCopied] = useState(false);
  const speakers = useMemo(() => {
    const uniqSpeakers = new Map<number, any>();

    call.transcripts.forEach((transcript) => {
      if (!uniqSpeakers.has(transcript.speaker_id)) {
        uniqSpeakers.set(transcript.speaker_id, {
          id: transcript.speaker_id,
          name: transcript.speaker,
        });
      }
    });

    return Array.from(uniqSpeakers.values());
  }, [call]);

  const speakerItems = useMemo(
    () => [
      { label: 'All speakers', id: DEFAULT_SPEAKER_ID },
      ...speakers.map((speaker) => ({
        label: (
          <div className="flex items-center gap-3 focus:outline-none">
            <Avatar size="xs" src={undefined} displayName={speaker.name} />
            <span className="truncate">{speaker.name}</span>
          </div>
        ),
        headerLabel: speaker.name,
        id: speaker.id.toString(),
      })),
    ],
    [speakers]
  );

  const transcriptsToShow = useMemo(() => {
    if (selectedSpeakerId === DEFAULT_SPEAKER_ID) {
      return call.transcripts;
    }

    return call.transcripts.filter(
      (transcript) => transcript.speaker_id.toString() === selectedSpeakerId
    );
  }, [selectedSpeakerId, call]);

  const handleDownloadTextClick = () => {
    const text = getTranscriptText(transcriptsToShow);
    const type = 'text/plain';
    const filename = `call-transcript(${call.name}).txt`;
    const blob = new Blob([text], { type });
    downloadFile(blob, { 'content-type': type }, filename);
  };
  const handleCopyTextClick = () => {
    const text = getTranscriptText(transcriptsToShow);
    navigator.clipboard.writeText(text);
    setCopied(true);
    analytics.track(AnalyticsEvents.CALL_TRANSCRIPT_COPIED, {
      callId: call.id,
    });
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="">
        {/*<div className="w-60 relative shrink-0">*/}
        {/*  <Icon*/}
        {/*    glyph={IconMap.SearchLg}*/}
        {/*    width={20}*/}
        {/*    className="absolute left-3.5 top-2.5 z-10 text-gray-500"*/}
        {/*  />*/}
        {/*  <Input*/}
        {/*    type="text"*/}
        {/*    placeholder="Search"*/}
        {/*    value=""*/}
        {/*    onChange={() => null}*/}
        {/*    heightSize="md"*/}
        {/*    className="!pl-10.5 w-80"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="flex justify-between gap-4">
          <Dropdown
            align="start"
            sideOffset={4}
            triggerStyles="!w-fit-content min-w-48 max-w-96"
            listStyles="shadow-lg min-w-48 max-w-96"
            itemStyles="min-w-44"
            checkmarkStyles="!ml-2"
            items={speakerItems}
            currentItemId={selectedSpeakerId ?? DEFAULT_SPEAKER_ID}
            onItemChange={(id) => setSelectedSpeakerId(id)}
          />
          <div className="flex items-center border border-gray-300 rounded-md text-gray-700 h-10 w-42">
            <Tooltip
              isOpen={copied}
              hideArrow
              sideOffset={6}
              trigger={
                <button
                  className="flex items-center gap-2 px-3.5 py-2 min-w-32 text-sm font-semibold"
                  type="button"
                  onClick={handleCopyTextClick}
                >
                  <Icon glyph={IconMap.Copy01} width={20} />
                  Copy text
                </button>
              }
            >
              Copied!
            </Tooltip>
            <Tooltip
              hideArrow
              sideOffset={6}
              trigger={
                <button
                  className="border-l border-gray-300 h-10 px-3 py-2.5"
                  type="button"
                  onClick={handleDownloadTextClick}
                >
                  <Icon glyph={IconMap.Download02} width={20} />
                </button>
              }
            >
              Download as .txt
            </Tooltip>
          </div>
        </div>
      </div>
      <ul className="flex flex-col gap-6">
        {transcriptsToShow.map((transcript) => (
          <li className="flex gap-4" key={transcript.words[0]?.start_timestamp}>
            <Avatar displayName={transcript.speaker} />
            <div>
              <p className="flex gap-1.5 items-center text-gray-500">
                <span className="font-semibold text-gray-600 text-sm">
                  {transcript.speaker}
                </span>
                <Icon glyph={IconMap.Dot} width={5} />
                <span className="text-xs">
                  {formatTranscriptTime(
                    `${transcript.words[0]?.start_timestamp}`
                  )}
                </span>
              </p>
              <p className="text-gray-900 text-md">
                {transcript.words.map((word) => word.text).join(' ')}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
