import { environment } from '../../environments/environment';

export const isDevelopment = environment.env === 'development';
export const isStagingEnvironment = environment.env === 'staging';
export const isTestEnvironment = environment.env === 'test';
export const isTest2Environment = environment.env === 'test-2';

export const PROTOCOL = isDevelopment ? 'http://' : 'https://';

export const API_URL = environment.apiUrl;

export const DATA_PROCESSING_API_URL = environment.dataProcessingApiUrl;

export const isMaintenanceModeEnabled =
  process.env['REACT_APP_MAINTENANCE_MODE_ENABLED'] === 'true';

export const sentryDSN = environment.sentryDsn;

export const APP_HOST = environment.appHost;
export const APP_ORIGIN = environment.appOrigin;

export const SEGMENT_WRITE_KEY = environment.segmentWriteKey;

export const TEMPLATE_SUBMIT_LINK = environment.templateSubmitLink;

export const GLEAP_API_KEY = environment.gleapApiKey;

export const LINKEDIN_CLIENT_ID = environment.linkedinClientId;

export const CLEARBIT_API_URL = environment.clearbitApiUrl;

export const GONG_CLIENT_ID = environment.gongClientId;

export const COLLABORATION_SERVER_URL = environment.collaborationServerUrl;

export const APP_URL = PROTOCOL + APP_HOST;
