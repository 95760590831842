import { CallSummaryGeneralContent } from '@distribute/shared/types';

export function getSummaryText(summary: CallSummaryGeneralContent) {
  const { subject, takeaways, topics, nextSteps } = summary;
  const subjectText = subject ? `Meeting Purpose\n${subject}\n\n` : '';
  const takeawaysText = takeaways
    ? `Key Takeaways\n${takeaways
        .map(({ text }) => `• ${text}`)
        .join('\n')}\n\n`
    : '';
  const topicsText = topics
    ? `Topics\n${topics
        .map(
          ({ subject, items }) =>
            `${subject}\n${items.map(({ text }) => `• ${text}`).join('\n')}`
        )
        .join('\n\n')}\n\n`
    : '';
  const nextStepsText = nextSteps
    ? `Next Steps\n${nextSteps.map(({ text }) => `• ${text}`).join('\n')}\n\n`
    : '';

  return `CallCall Summary\n\n${subjectText}${takeawaysText}${topicsText}${nextStepsText}`;
}
