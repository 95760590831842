import { call, put, select } from 'redux-saga/effects';
import { pageCreationFlowModel } from '..';
import { RT } from '../../../../shared/types';
import { pagesModel } from '../../../../features/pages';
import { teamsModel } from '../../../../features/teams';
import { PageCreationDataSourcesEnum } from '../../config/types';
import { authUserModel } from '../../../../entities/auth-user';
import { foldersModel } from '../../../../features/folders';
import { createPage } from './createPage';
import { logger } from '../../../../shared/lib';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';

export function* initializePageGenerationWithAI({
  payload: formData,
}: ReturnType<
  typeof pageCreationFlowModel.actions.initializePageGenerationWithAI
>) {
  try {
    yield put(pageCreationFlowModel.actions.setIsGeneratePageLoading(true));

    const user: RT<typeof authUserModel.selectors.selectUserWithError> =
      yield select(authUserModel.selectors.selectUserWithError);

    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const embeddedContext: RT<
      typeof pageCreationFlowModel.selectors.selectEmbeddedContext
    > = yield select(pageCreationFlowModel.selectors.selectEmbeddedContext);

    const contextRawData: RT<
      typeof pageCreationFlowModel.selectors.selectContextRawData
    > = yield select(pageCreationFlowModel.selectors.selectContextRawData);

    if (!formData.pageType) {
      const error = 'Page type is not defined';

      logger.error(error);
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', error)
        )
      );
      return;
    }

    yield put(
      pagesModel.actions.setGeneratePagePromptData({
        pageType: formData.pageType,
        pageTypeDescription: formData.pageTypeDescription,
        userName: user.displayName,
        companyName: currentTeam.brandCompanyName ?? undefined,
        aboutCompany: currentTeam.brandCompanyInfo ?? undefined,
        pageTitle: formData.pageTitle,
        prospectName: formData.selectedCallerName,
        teamId: currentTeam.id,
        contextRawData: contextRawData ?? [],
        isTranscript:
          formData.selectedDataSource === PageCreationDataSourcesEnum.GONG,
        hasEmbeddings: !!embeddedContext,
        embeddedContext: embeddedContext ?? undefined,
        generatedOutline: formData.sections,
        isSeparateTabs: formData.isSeparateTabs,
        brandCompany: formData.brandCompany?.name ?? undefined,
      })
    );

    const currentFolder: RT<typeof foldersModel.selectors.selectCurrentFolder> =
      yield select(foldersModel.selectors.selectCurrentFolder);

    const chosenFolderData: RT<
      typeof pageCreationFlowModel.selectors.selectChosenFolderData
    > = yield select(pageCreationFlowModel.selectors.selectChosenFolderData);

    yield call(createPage, {
      title: formData.pageTitle,
      brandLogo: formData.brandCompany?.logo ?? null,
      brandCompanyDomain: formData.brandCompany?.domain ?? null,
      folderId: currentFolder
        ? currentFolder.id
        : chosenFolderData?.folderId ?? '',
      folderName: currentFolder
        ? currentFolder.title
        : chosenFolderData?.folderName ?? '',
      isSeparateTabs: formData.isSeparateTabs,
      generatedOutline: formData.sections,
    });
    yield put(pageCreationFlowModel.actions.closePageCreationFlow());
  } catch (error) {
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to initialize page generation')
      )
    );
  } finally {
    yield put(pageCreationFlowModel.actions.setIsGeneratePageLoading(false));
  }
}
