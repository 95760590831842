import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { Badge, Button, Dropdown, Icon, Tooltip } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { subscriptionModel } from '../../subscription';
import { useSubscriptionLimits } from '../../subscription/hooks';
import { GetCallDetailResponse } from '@distribute/shared/api-types/call';
import { getSummaryText } from '../lib/getSummaryText';
import { downloadFile } from '../../../shared/lib';
import { analytics } from '../../../entities/analytics';
import { AnalyticsEvents } from '@distribute/shared/types';

type CallSummaryProps = {
  call: GetCallDetailResponse;
};

const SummaryTypes = {
  general: 'general',
  sales: 'sales',
  qa: 'qa',
  customerSuccess: 'customerSuccess',
  projectKickOff: 'projectKickOff',
} as const;

type SummaryLabes = {
  id: string;
  title: string;
  description: string;
  isComingSoon?: boolean;
};

const summaryTypeLabels: Record<string, SummaryLabes> = {
  [SummaryTypes.general]: {
    id: SummaryTypes.general,
    title: 'General',
    description: "Capture any call's insights and key takeaways.",
  },
  [SummaryTypes.sales]: {
    id: SummaryTypes.sales,
    title: 'Sales',
    description: "Unpack a prospect's needs, challenges, and buying journey.",
    isComingSoon: true,
  },
  [SummaryTypes.qa]: {
    id: SummaryTypes.qa,
    title: 'Q&A',
    description: 'Recap questions with answers.',
    isComingSoon: true,
  },
  [SummaryTypes.customerSuccess]: {
    id: SummaryTypes.customerSuccess,
    title: 'Customer Success',
    description: 'Experiences, challenges, goals, and Q&A.',
    isComingSoon: true,
  },
  [SummaryTypes.projectKickOff]: {
    id: SummaryTypes.projectKickOff,
    title: 'Project Kick-Off',
    description: 'Vision, targets, and resources.',
    isComingSoon: true,
  },
};

export const CallSummary: React.FC<CallSummaryProps> = ({ call }) => {
  const dispatch = useDispatch();
  const [summaryIndex, setSummaryIndex] = useState(0);
  const [copied, setCopied] = useState(false);
  const subscriptionLimits = useSubscriptionLimits();
  const hasFreePlan = !subscriptionLimits.showCallSummary;
  const { summaries } = call;

  const handleUpgradeClick = () => {
    dispatch(subscriptionModel.actions.setIsShowPaywallModal(true));
  };

  const summaryItems = Object.values(summaryTypeLabels).map(
    (summary, index) => ({
      label: (
        <div className="">
          <span
            className={cn('flex items-center gap-1.5', {
              'text-gray-700': !summary.isComingSoon,
            })}
          >
            {summary.title}
            {summary.isComingSoon && (
              <Badge theme="disabled" size="sm">
                Soon
              </Badge>
            )}
          </span>
          <span
            className={cn('text-xs', {
              'text-gray-500': !summary.isComingSoon,
            })}
          >
            {summary.description}
          </span>
        </div>
      ),
      id: summary.id,
      headerLabel: summary.title,
      onClick: () => setSummaryIndex(index),
      disabled: summary.isComingSoon,
    })
  );
  const currentSummary = summaries[summaryIndex];

  const handleDownloadTextClick = () => {
    const text = getSummaryText(currentSummary.content);
    const type = 'text/plain';
    const filename = `call-summary(${call.name}).txt`;
    const blob = new Blob([text], { type });
    downloadFile(blob, { 'content-type': type }, filename);
  };

  const handleCopyTextClick = () => {
    const text = getSummaryText(currentSummary.content);
    navigator.clipboard.writeText(text);
    setCopied(true);
    analytics.track(AnalyticsEvents.CALL_SUMMARY_COPIED, {
      callId: call.id,
    });
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  if (hasFreePlan) {
    return (
      <div className="flex flex-col items-center justify-center gap-1.5 text-gray-700">
        <h2 className="text-md text-gray-900 font-semibold">
          You are on free plan
        </h2>
        <p className="text-sm text-gray-600 pb-6">
          Please upgrade to see you call summary.
        </p>
        <Button variant="text" color="primary" onClick={handleUpgradeClick}>
          Upgrade
        </Button>
      </div>
    );
  }

  if (summaries.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-1.5 text-gray-700">
        <span className="border border-gray-200 rounded-lg p-3 shadow-xs">
          <Icon glyph={IconMap.Hourglass03} width={24} />
        </span>
        <h2 className="text-md text-gray-900 font-semibold">
          Preparing your summary
        </h2>
        <p className="text-sm text-gray-600">
          Your recording is being analyzed.
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <Dropdown
          currentItemId={currentSummary?.type}
          triggerStyles="!w-42"
          align="start"
          listStyles="shadow-lg"
          itemStyles="py-1.5"
          isModalMode={false}
          items={summaryItems}
        />

        <div className="flex items-center border border-gray-300 rounded-md text-gray-700 h-10 w-42">
          <Tooltip
            isOpen={copied}
            hideArrow
            sideOffset={6}
            trigger={
              <button
                className="flex items-center gap-2 px-3.5 py-2 min-w-32 text-sm font-semibold"
                type="button"
                onClick={handleCopyTextClick}
              >
                <Icon glyph={IconMap.Copy01} width={20} />
                Copy text
              </button>
            }
          >
            Copied!
          </Tooltip>
          <Tooltip
            hideArrow
            sideOffset={6}
            trigger={
              <button
                className="border-l border-gray-300 h-10 px-3 py-2.5"
                type="button"
                onClick={handleDownloadTextClick}
              >
                <Icon glyph={IconMap.Download02} width={20} />
              </button>
            }
          >
            Download as .txt
          </Tooltip>
        </div>
      </div>
      {currentSummary?.content && (
        <div className="flex flex-col gap-6 text-gray-900 text-md py-6">
          <div className="flex flex-col gap-4">
            <h2 className="font-bold text-2xl">Meeting Purpose</h2>
            <p>{currentSummary.content.subject}</p>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="font-bold text-2xl">Key Takeaways</h2>
            <ol className="list-disc pl-10">
              {currentSummary.content.takeaways.map((takeaway) => (
                <li key={takeaway.timestamp}>{takeaway.text}</li>
              ))}
            </ol>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="font-bold text-2xl">Topics</h2>
            <div className="flex flex-col gap-4">
              {currentSummary.content.topics.map((topic) => (
                <div className="flex flex-col gap-2" key={topic.subject}>
                  <h3 className="font-bold text-xl">{topic.subject}</h3>
                  <ol className="list-disc pl-10">
                    {topic.items.map((item) => (
                      <li key={item.timestamp}>{item.text}</li>
                    ))}
                  </ol>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="font-bold text-2xl">Next Steps</h2>
            <ol className="list-disc pl-10">
              {currentSummary.content.nextSteps.map((nextStep) => (
                <li key={nextStep.timestamp}>{nextStep.text}</li>
              ))}
            </ol>
          </div>
        </div>
      )}
    </div>
  );
};
