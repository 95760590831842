import { selectWithError } from '../../../shared/lib';
import { RootState } from '../../../app';

export const selectCalls = ({ call }: RootState) => call.calls;
export const selectCallListOptions = ({ call }: RootState) => call.listOptions;
export const selectCallListMeta = ({ call }: RootState) => call.listMeta;
export const selectIsCallListLoading = ({ call }: RootState) =>
  call.isListLoading;

export const selectEditingCallItem = ({ call }: RootState) =>
  call.editingCallItem;
export const selectDeletingCallItem = ({ call }: RootState) =>
  call.deletingCallItem;
export const selectTogglingCallItem = ({ call }: RootState) =>
  call.togglingCallItem;

export const selectEditingCallItemWithError = selectWithError(
  selectEditingCallItem,
  'editingCallItem'
);

export const selectDeletingCallItemWithError = selectWithError(
  selectDeletingCallItem,
  'deletingCallItem'
);

export const selectTogglingCallItemWithError = selectWithError(
  selectTogglingCallItem,
  'togglingCallItem'
);

export const selectIsCallEditingProgress = ({ call }: RootState) =>
  call.isCallEditingProgress;
export const selectIsCallDeletingProgress = ({ call }: RootState) =>
  call.isCallDeletingProgress;

export const selectCurrentCall = ({ call }: RootState) => call.currentCall;
export const selectCurrentCallWithError = selectWithError(
  selectCurrentCall,
  'currentCall'
);
export const selectIsCurrentCallLoading = ({ call }: RootState) =>
  call.isCurrentCallLoading;

export const selectTotal = ({ call }: RootState) => call.total;

export const selectIsCallCountLoading = ({ call }: RootState) =>
  call.isCallCountLoading;
