import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import {
  CreatePageActiveScreenEnum,
  CreatePageFormSchemaType,
  LinkTypeForAIStatusEnum,
  PageCreationDataSourcesEnum,
  PageCreationTypesEnum,
  TranscriptsFilterTypesEnum,
} from '../../config/types';
import { CreateWithAIPageType } from '@distribute/shared/api-types/create-with-ai';
import { useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../model';

type CreatePageStepContextType = {
  activeScreen: CreatePageActiveScreenEnum;
  setActiveScreen: (screen: CreatePageActiveScreenEnum) => void;
  isAppearanceDone: boolean;
  isPageTypeDone: boolean;
  isSourceDone: boolean;
  isAllDone: boolean;
};

export const CreatePageStepContext =
  createContext<CreatePageStepContextType | null>(null);

export const useCreatePageStepContext = (): CreatePageStepContextType => {
  const ctx = useContext(CreatePageStepContext);

  if (!ctx) {
    throw new Error(
      'useCreatePageStepContext must be used within a CreatePageStepContext'
    );
  }

  return ctx;
};

export const CreatePageStepProvider = ({ children }: PropsWithChildren) => {
  const pageCreationType = useSelector(
    pageCreationFlowModel.selectors.selectPageCreationType
  );
  const [activeScreen, setActiveScreen] = useState<CreatePageActiveScreenEnum>(
    CreatePageActiveScreenEnum.BrandedPage
  );
  const { watch } = useFormContext<CreatePageFormSchemaType>();
  const isBranded = watch('isBranded');
  const brandCompany = watch('brandCompany');
  const pageType = watch('pageType');
  const pageTypeDescription = watch('pageTypeDescription');
  const dataSource = watch('selectedDataSource');
  const links = watch('links');
  const files = watch('files');
  const transcripts = watch('selectedTranscripts');
  const transcriptFilter = watch('transcriptsFilter');
  const text = watch('text');

  const isAppearanceDone =
    pageCreationType === PageCreationTypesEnum.TAB
      ? true
      : isBranded
      ? !!brandCompany
      : true;
  const isPageTypeDone =
    pageType === CreateWithAIPageType.OTHER
      ? !!pageTypeDescription
      : !!pageType;

  const isSourceDone = useMemo(() => {
    switch (dataSource) {
      case PageCreationDataSourcesEnum.GONG:
        return transcriptFilter === TranscriptsFilterTypesEnum.SPECIFIC
          ? !!transcripts.length
          : !!transcriptFilter;
      case PageCreationDataSourcesEnum.FILE:
        return !!files?.length;
      case PageCreationDataSourcesEnum.LINK:
        return !!links?.every(
          (link) => link.status === LinkTypeForAIStatusEnum.PARSED
        );
      case PageCreationDataSourcesEnum.TEXT:
        return text.length > 5;
      default:
        return false;
    }
  }, [
    dataSource,
    files?.length,
    links,
    text.length,
    transcriptFilter,
    transcripts.length,
  ]);

  return (
    <CreatePageStepContext.Provider
      value={{
        activeScreen,
        setActiveScreen,
        isAppearanceDone,
        isPageTypeDone,
        isSourceDone,
        isAllDone: isAppearanceDone && isPageTypeDone && isSourceDone,
      }}
    >
      {children}
    </CreatePageStepContext.Provider>
  );
};
