import React from 'react';
import { useSelector } from 'react-redux';
import {
  CallHeader,
  DeleteCallItemModal,
  CallDetails,
  TogglePrivacyCallItemModal,
} from '../../../features/call';
import { callModel } from '../../../features/call';
import { Loader } from '../../../shared/ui';

export const CallPage = () => {
  const currentCall = useSelector(callModel.selectors.selectCurrentCall);
  const deletingCallItem = useSelector(
    callModel.selectors.selectDeletingCallItem
  );
  const togglingCallItem = useSelector(
    callModel.selectors.selectTogglingCallItem
  );

  if (!currentCall) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col gap-6 h-full">
      <CallHeader call={currentCall} />
      <CallDetails call={currentCall} />
      {deletingCallItem && <DeleteCallItemModal />}
      {togglingCallItem && <TogglePrivacyCallItemModal />}
    </div>
  );
};
