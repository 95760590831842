import Cross from './icons/cross.svg';
import Logout from './icons/log-out.svg';
import Eye from './icons/eye-icon.svg';
import CrossedEye from './icons/crossed-eye-icon.svg';
import Info from './icons/info.svg';
import ListCheckMark from './icons/list-check-mark.svg';
import DistributeLogo from './icons/distribute-logo.svg';
import DistributeLogoLetter from './icons/distribute-logo-letter.svg';
import AlertCircle from './icons/alert-circle.svg';
import Check from './icons/check.svg';
import ColorCheck from './icons/color-check.svg';
import PDFIcon from './icons/pdf-icon.svg';
import CheckCircleGreen from './icons/check-circle-green.svg';
import CrossCircleRed from './icons/cross-circle-red.svg';
import CrossClose from './icons/cross-close.svg';
import NavMenuButton from './icons/nav-menu-button.svg';
import Plus from './icons/plus.svg';
import PlusCircle from './icons/plus-circle.svg';
import PlusSquare from './icons/plus-square.svg';
import DotsVertical from './icons/dots-vertical.svg';
import DotsVerticalBold from './icons/dots-vertical-bold.svg';
import AddFolder from './icons/add-folder.svg';
import Avatar from './icons/avatar.svg';
import Upload from './icons/upload.svg';
import Film02 from './icons/film-02.svg';

import GreenCheckMark from './icons/green-check-mark.svg';
import Drag from './icons/drag.svg';
import Trash from './icons/trash.svg';
import MenuCopy from './icons/dropdown-menu/copy.svg';
import MenuEdit from './icons/dropdown-menu/edit.svg';
import MenuEye from './icons/dropdown-menu/eye.svg';
import MenuFolder from './icons/dropdown-menu/folder.svg';
import MenuLink from './icons/dropdown-menu/link.svg';
import Mail01 from './icons/mail-01.svg';
import Mail02 from './icons/mail-02.svg';
import LinkBroken from './icons/link-broken.svg';
import UserPlus from './icons/dropdown-menu/user-plus-01.svg';
import UserCircle from './icons/user-circle.svg';
import ArrowDown from './icons/arrow-down.svg';
import ArrowRight from './icons/arrow-right.svg';
import ArrowLeft from './icons/arrow-left.svg';
import ArrowCircleUp from './icons/arrow-circle-up.svg';
import ArrowCircleDown from './icons/arrow-circle-down.svg';
import LinkExternal from './icons/link-external.svg';
import DotsHorizontal from './icons/dots-horizontal.svg';
import BackArrow from './icons/back-arrow.svg';
import Checked from './icons/checked.svg';
import ReverseLeft from './icons/reverse-left.svg';
import ReverseRight from './icons/reverse-right.svg';
import ColorPalette from './icons/color-pallette.svg';
import Headings from './icons/headings.svg';
import Paragraphs from './icons/paragraphs.svg';
import Conversion from './icons/conversion.svg';
import ToolbarSettings from './icons/toolbar-settings.svg';
import Star from './icons/star.svg';
import VideoRecorder from './icons/video-recorder.svg';
import Picture from './icons/picture.svg';
import Document from './icons/document.svg';
import TableIcon from './icons/table-icon.svg';
import Delete from './icons/delete.svg';
import MoveToFolder from './icons/move-to-folder.svg';
import Duplicate from './icons/duplicate.svg';
import Link from './icons/link-icon.svg';
import Link02 from './icons/link-02.svg';
import AlertBarIcon from './icons/alert-bar.svg';
import PopupIcon from './icons/popup.svg';
import ActionBannerIcon from './icons/action-banner.svg';
import GatedContentIcon from './icons/gated-content.svg';
import SqueezePageIcon from './icons/squeeze-page.svg';
import UploadCloud from './icons/upload-cloud.svg';
import SquareIcon from './icons/square.svg';
import XClose from './icons/x-close.svg';
import LifeBuoy from './icons/life-buoy.svg';
import Settings from './icons/settings.svg';
import Settings02 from './icons/settings-02.svg';
import RefreshCcw from './icons/refresh-ccw.svg';
import Youtube from './icons/youtube.svg';
import TooltipArrow from './icons/tooltip-arrow.svg';
import ChevronLeft from './icons/chevron-left.svg';
import ChevronRight from './icons/chevron-right.svg';
import ChevronDown from './icons/chevron-down.svg';
import File04 from './icons/file-04.svg';
import File05 from './icons/file-05.svg';
import FileDocument from './icons/file-document.svg';
import SearchSm from './icons/search-sm.svg';
import SearchMd from './icons/search-md.svg';
import XCircle from './icons/x-circle.svg';
import Lock01 from './icons/lock-01.svg';
import Attachment02 from './icons/attachment-02.svg';
import Logotype from './icons/logotype.svg';
import Gmail1 from './icons/gmail-1.svg';
import Gmail2 from './icons/gmail-2.svg';
import Globe03 from './icons/globe-03.svg';
import Globe04 from './icons/globe-04.svg';
import GongLg from './icons/gong-lg.svg';

import GoogleChrome from './icons/google-chrome.svg';

import ArialFont from './icons/fonts/arial.svg';
import BitterFont from './icons/fonts/bitter.svg';
import DancingScriptFont from './icons/fonts/dancing-script.svg';
import DroidSansFont from './icons/fonts/droid.svg';
import FigtreeFont from './icons/fonts/figtree.svg';
import ExoFont from './icons/fonts/exo.svg';
import GeorgiaFont from './icons/fonts/georgia.svg';
import InconsolataFont from './icons/fonts/inconsolata.svg';
import InterFont from './icons/fonts/inter.svg';
import LatoFont from './icons/fonts/lato.svg';
import MerriWeatherFont from './icons/fonts/merriweather.svg';
import MontserratFont from './icons/fonts/montserrat.svg';
import OpenSansFont from './icons/fonts/open-sans.svg';
import OswaldFont from './icons/fonts/oswald.svg';
import PalationFont from './icons/fonts/palatino.svg';
import PTSansFont from './icons/fonts/palatino.svg';
import PTSerifFont from './icons/fonts/pt-serif.svg';
import RobotoFont from './icons/fonts/roboto.svg';
import RubikFont from './icons/fonts/rubik.svg';
import TahomaFont from './icons/fonts/tahoma.svg';
import TimesNewRomanFont from './icons/fonts/times-new-roman.svg';
import TrebuchetMSFont from './icons/fonts/trebuchet-ms.svg';
import UbuntuFont from './icons/fonts/ubuntu.svg';
import VerdanaFont from './icons/fonts/verdana.svg';
import VollkornFont from './icons/fonts/vollkorn.svg';
import SystemUIFont from './icons/fonts/system.svg';

import EditIcon from './icons/edit-icon.svg';
import Edit2Icon from './icons/edit-2-icon.svg';
import Edit02 from './icons/edit-02.svg';
import Edit05 from './icons/edit-05.svg';
import AnalyticsIcon from './icons/analytics.svg';
import Sidebar from './icons/sidebar.svg';
import Target04 from './icons/target-04.svg';
import PuzzlePiece01 from './icons/puzzle-piece-01.svg';
import PuzzlePiece02 from './icons/puzzle-piece-02.svg';
import FlexAlignBottom from './icons/flex-align-bottom.svg';
import Type01 from './icons/type-01.svg';
import TypeStrikethrough from './icons/type-strikethrough.svg';
import TypeSquare from './icons/type-square.svg';
import Rows01 from './icons/rows-01.svg';
import Calendar from './icons/calendar.svg';
import CalendarPlus01 from './icons/calendar-plus-01.svg';
import Image01 from './icons/image-01.svg';
import Image05 from './icons/image-05.svg';
import ImagePlus from './icons/image-plus.svg';
import ImageIndentLeft from './icons/image-indent-left.svg';
import ImageIndentRight from './icons/image-indent-right.svg';
import ImageX from './icons/Image-X.svg';
import AlignLeft from './icons/align-left.svg';
import User03 from './icons/user-03.svg';
import EmailSymbol from './icons/email-symbol.svg';
import Paperclip02 from './icons/paperclip-02.svg';
import Phone from './icons/phone.svg';
import MessageDotsCircle from './icons/message-dots-circle.svg';
import ClockStopwatch from './icons/clock-stopwatch.svg';
import LogOut02 from './icons/log-out-02.svg';

import DesktopIcon from './icons/desktop.svg';
import TabletIcon from './icons/tablet.svg';
import MobileIcon from './icons/mobile.svg';
import CloudOffIcon from './icons/cloud-off.svg';
import MagicWand from './icons/magic-wand.svg';

import CalendlyLogoSm from './icons/integrations/calendly-logo-sm.svg';
import Hubspot from './icons/integrations/hubspot.svg';
import Salesforce from './icons/integrations/salesforce.svg';
import ChiliPiperLogoSm from './icons/integrations/chili-piper-logo-sm.svg';
import CalendlyLogo from './icons/integrations/calendly-logo.svg';
import ChiliPiperLogo from './icons/integrations/chili-piper-logo.svg';
import Zapier from './icons/integrations/zapier.svg';

import BarChart01 from './icons/bar-chart-10.svg';
import BarChartSquare01 from './icons/bar-chart-square-01.svg';
import File02 from './icons/file-02.svg';
import Settings01 from './icons/settings-01.svg';
import Users01 from './icons/users-01.svg';
import ChevronUp from './icons/chevron-up.svg';
import HelpCircle from './icons/help-circle.svg';
import FilterFunnel02 from './icons/filter-funnel-02.svg';
import SwitchVertical01 from './icons/switch-vertical-01.svg';
import LayoutAlt03 from './icons/layout-alt-03.svg';
import AlertTriangle from './icons/alert-triangle.svg';
import CursorClick02 from './icons/cursor-click-02.svg';
import VerifiedCheck from './icons/virified-check.svg';

import Play from './icons/play.svg';
import Pause from './icons/pause.svg';
import Stop from './icons/stop.svg';
import Restart from './icons/restart.svg';
import Cancel from './icons/cancel.svg';

import Recording from './icons/recording.svg';
import RecordingStart from './icons/recording-start.svg';
import Microphone from './icons/microphone.svg';
import Microphone1 from './icons/microphone-1.svg';
import Microphone02 from './icons/microphone-02.svg';
import Camera from './icons/camera.svg';

// editor icons
import AlignCenter from './icons/align-center.svg';
import AlignRight from './icons/align-right.svg';
import AlignJustify from './icons/align-justify.svg';
import Bold from './icons/bold.svg';
import BoldSquare from './icons/bold-square.svg';
import Italic from './icons/italic.svg';
import Underline from './icons/underline.svg';
import Strikethrough from './icons/strikethrough.svg';
import Letter from './icons/letter.svg';
import ListBulleted from './icons/list-bulleted.svg';
import ListNumbered from './icons/list-numbered.svg';
import Quote from './icons/quote.svg';
import Table from './icons/table.svg';
import CellEdit from './icons/cell-edit.svg';
import LayoutTop from './icons/layout-top.svg';
import LayoutLeft from './icons/layout-left.svg';
import AddColumn from './icons/add-column.svg';
import AddRow from './icons/add-row.svg';
import MergeCells from './icons/merge-cells.svg';
import SplitCell from './icons/split-cell.svg';
import RemoveColumn from './icons/remove-column.svg';
import RemoveRow from './icons/remove-row.svg';
import DropCross from './icons/drop-cross.svg';
import TiptapFile from './icons/tiptap-file.svg';
import TiptapImage from './icons/tiptap-image.svg';
import TiptapTable from './icons/tiptap-table.svg';
import TiptapVideo from './icons/tiptap-video.svg';
import TiptapDivider from './icons/tiptap-divider.svg';
import TiptapCallout from './icons/tiptap-callout.svg';
import PhotoLeft from './icons/photo-left.svg';
import PhotoRight from './icons/photo-right.svg';
import PhotoCenter from './icons/photo-center.svg';
import PoweredByDistribute from './icons/powered-by-distribute.svg';
import Eraser from './icons/eraser.svg';
import RollerBrush from './icons/roller-brush.svg';
import AlignVerTop from './icons/align-ver-top.svg';
import AlignVerCenter from './icons/align-ver-center.svg';
import AlignVerBottom from './icons/align-ver-bottom.svg';
import EnterKey from './icons/enter-key.svg';
import ArrowCircleRight from './icons/arrow-circle-right.svg';
import ArrowCircleRightGreen from './icons/arrow-circle-right-green.svg';
import StopCircle from './icons/stop-circle.svg';
import CheckCircle from './icons/check-circle.svg';
import RefreshCcw04 from './icons/refresh-ccw-04.svg';
import RefreshCcw05 from './icons/refresh-ccw-05.svg';
import RefreshCw05 from './icons/refresh-cw-5.svg';
import Edit04 from './icons/edit-04.svg';
import SearchCheck from './icons/search-check.svg';
import ShortText from './icons/short-text.svg';
import LongText from './icons/long-text.svg';
import Mic from './icons/mic.svg';
import Stars02 from './icons/stars-02.svg';
import Stars03 from './icons/stars-03.svg';
import Summary from './icons/summary.svg';
import SwitchHorizontal01 from './icons/switch-horizontal-01.svg';
import LeftIndent03 from './icons/left-indent-03.svg';
import TabSymbol from './icons/tab-symbol.svg';
import MagicWand01 from './icons/magic-wand-01.svg';
import ArrowSquareUpRight from './icons/arrow-square-up-right.svg';
import LayoutBottom from './icons/layout-bottom.svg';
import Menu02 from './icons/menu-02.svg';
import SpacingVertical from './icons/spacing-vertical.svg';
import Share07 from './icons/share-07.svg';
import PhotoPictureUser from './icons/photo-picture-user.svg';
import Template from './icons/template.svg';
import AdvancedEditor from './icons/advanced-editor.svg';
import Menubar from './icons/menubar.svg';
import ChatMessage from './icons/chat-message.svg';
import CursorButton from './icons/cursor-button.svg';
import BrowserWebGraphAnalytics from './icons/browser-web-graph-analytics.svg';
import CheckGreenBg from './icons/check-green-bg.svg';
import ChevronSelectorVertical from './icons/chevron-selector-vertical.svg';
import BulletListSlash from './icons/bullet-list-slash.svg';
import NumberedListSlash from './icons/numbered-list-slash.svg';
import QuoteSlash from './icons/quote-slash.svg';
import Linkedin from './icons/linkedin.svg';
import LinkedinDisabled from './icons/linkedin-disabled.svg';
import Facebook from './icons/facebook.svg';
import Twitter from './icons/twitter.svg';
import TwitterDisabled from './icons/twitter-disabled.svg';
import H1 from './icons/heading1.svg';
import H2 from './icons/heading2.svg';
import H3 from './icons/heading3.svg';
import Rocket02 from './icons/rocket-02.svg';
import Party from './icons/party.svg';
import Placeholder from './icons/placeholder.svg';
import PlayCircle from './icons/play-circle.svg';
import LayersThree01 from './icons/layers-three-01.svg';
import ToggleListSlash from './icons/toggle-list-slash.svg';
import ToggleIcon from './icons/toggle-icon.svg';
import Tag02 from './icons/tag-02.svg';
import CreditCardRefresh from './icons/credit-card-refresh.svg';
import VisaLogo from './icons/visa-logo.svg';
import UnionPayLogo from './icons/union-pay-logo.svg';
import MastercardLogo from './icons/mastercard-logo.svg';
import JcbLogo from './icons/jcb-logo.svg';
import DinnersLogo from './icons/dinners-logo.svg';
import AmexLogo from './icons/amex-logo.svg';
import UnknownCardBrand from './icons/unknown-card-brand.svg';
import StripeText from './icons/stripe-text.svg';
import CreditCard02 from './icons/credit-card-02.svg';
import InfoCircle from './icons/info-circle.svg';
import Sale03 from './icons/sale-03.svg';
import CheckVerified01 from './icons/check-verified-01.svg';
import ArrowNarrowRight from './icons/arrow-narrow-right.svg';
import Google from './icons/google.svg';
import GoogleDrive from './icons/google-drive.svg';
import Microsoft from './icons/microsoft.svg';
import Loom from './icons/loom.svg';
import Calendly from './icons/calendly.svg';
import Copy01 from './icons/copy-01.svg';
import Copy03 from './icons/copy-03.svg';
import Copy07 from './icons/copy-07.svg';
import Laptop01 from './icons/laptop-01.svg';
import Tablet01 from './icons/tablet-01.svg';
import Phone01 from './icons/phone-01.svg';
import TiptapTodoList from './icons/tiptap-todo-list.svg';
import TiptapButton from './icons/tiptap-button.svg';
import TiptapEmbedContent from './icons/tiptap-embed-content.svg';
import Tiptap2Columns from './icons/tiptap-2-columns.svg';
import Tiptap3Columns from './icons/tiptap-3-columns.svg';
import Tiptap4Columns from './icons/tiptap-4-columns.svg';
import TiptapCodeBlock from './icons/tiptap-code-block.svg';
import FaceSmile from './icons/face-smile.svg';
import Paint from './icons/paint.svg';
import Star06 from './icons/star-06.svg';
import TextLines from './icons/text-lines.svg';
import UploadCloud02 from './icons/upload-cloud-02.svg';

import WorkspaceSettings from './icons/workspace-settings.svg';
import Palette from './icons/palette.svg';
import Members from './icons/members.svg';
import Award from './icons/award.svg';
import PlusLight from './icons/plus-light.svg';
import SmallPlus from './icons/small-plus.svg';
import Building07 from './icons/building-07.svg';
import ChevronSelectorVerticalBold from './icons/chevron-selector-vertical-bold.svg';
import Film from './icons/film.svg';
import FilmArrow from './icons/film-arrow.svg';
import FilmCross from './icons/film-cross.svg';
import Files from './icons/files.svg';
import Templates from './icons/templates.svg';
import UserSquare from './icons/user-square.svg';
import AnalyticsChart from './icons/analytics-chart.svg';
import Users from './icons/users.svg';
import Lock from './icons/lock.svg';
import LockFilled from './icons/lock-filled.svg';
import User from './icons/user.svg';
import PieChartFilled from './icons/pie-chart-filled.svg';
import Lead from './icons/lead.svg';
import RecentViewers from './icons/recent-viewers.svg';
import DownloadCloud01 from './icons/download-cloud-01.svg';
import Download from './icons/download.svg';

import TimeLoadingBack from './icons/time-loading-back.svg';
import Trophy from './icons/trophy-01.svg';
import PrimaryCircleLines from './icons/primary-circle-lines.svg';
import Share06 from './icons/share-06.svg';
import BgSquare from './icons/bg-square.svg';
import SearchLg from './icons/search-lg.svg';
import SlashCircle01 from './icons/slash-circle-01.svg';
import TeamAccess from './icons/team-access.svg';
import EyeBold from './icons/eye-bold.svg';
import EyeCrossedBold from './icons/eye-crossed-bold.svg';
import DraftPencil from './icons/draft-pencil.svg';
import SearchRefraction from './icons/search-refraction.svg';
import TemplatesPlus from './icons/templates-plus.svg';

import SharePublicly from './icons/share-publicly.svg';
import InviteMembers from './icons/invite-members.svg';
import Export from './icons/export.svg';
import Embed from './icons/embed.svg';
import Shield02 from './icons/shield-02.svg';
import Snippets from './icons/snippets.svg';
import LayoutAlt02 from './icons/layout-alt-02.svg';
import Shape01 from './icons/shapes-01.svg';
import Shape02 from './icons/shapes-02.svg';
import SnippetText from './icons/snippet-text.svg';
import SnippetImage from './icons/snippet-image.svg';
import SnippetVideo from './icons/snippet-video.svg';
import SnippetFile from './icons/snippet-file.svg';
import PlaySquare from './icons/play-square.svg';
import BlankPageSquare from './icons/blank-page-square.svg';
import BookClosed from './icons/book-closed.svg';

import Records from './icons/records.svg';

import Heading1 from './icons/h1.svg';
import Heading2 from './icons/h2.svg';
import Heading3 from './icons/h3.svg';
import BulletList from './icons/bullet-list-2.svg';
import NumberList from './icons/number-list-2.svg';
import ToggleList from './icons/toggle-list.svg';
import CheckSquare from './icons/check-square.svg';
import Blockquote from './icons/block-quote.svg';
import Announcement01 from './icons/announcement-01.svg';
import Divider from './icons/divider.svg';
import ButtonClick from './icons/button-click.svg';
import ShortcutEnter from './icons/shortcut-enter.svg';
import CodeSnippet02 from './icons/code-snippet-02.svg';
import Columns02 from './icons/columns-02.svg';
import Columns03 from './icons/columns-03.svg';
import Columns04 from './icons/columns-04.svg';
import CalendlyLogoCurrent from './icons/calendly-logo-current.svg';
import PlayVideo from './icons/play-video.svg';
import Grid02 from './icons/grid-02.svg';
import ArrowCircleUpRight from './icons/arrow-circle-up-right.svg';
import SnippetMenuText from './icons/snippet-menu-text.svg';
import SnippetMenuImg from './icons/snippet-menu-img.svg';
import SnippetMenuVideo from './icons/snippet-menu-video.svg';
import ArrowsLeftRight from './icons/arrows-left-right.svg';
import WizardWrite from './icons/wizard-write.svg';
import GoogleDrive1 from './icons/google-drive-1.svg';
import GoogleMeet from './icons/google-meet.svg';
import Route from './icons/route.svg';
import ClipboardCheck from './icons/clipboard-check.svg';
import ArrowSquareLeft from './icons/arrow-square-left.svg';
import UserBadge from './icons/user-badge.svg';
import AtSign from './icons/at-sign.svg';
import Globe01 from './icons/globe-01.svg';
import XSocial from './icons/x-social.svg';
import FacebookLine from './icons/facebook-line.svg';
import LinkedInLine from './icons/linkedin-line.svg';
import CheckCircleBroken from './icons/check-circle-broken.svg';
import PlaceholderDashed from './icons/placeholder-dashed.svg';
import Expand01 from './icons/expand-01.svg';
import Download02 from './icons/download-02.svg';
import Document01 from './icons/document-01.svg';
import Hourglass03 from './icons/hourglass-03.svg';

// Trend Badge Icon
import Dot from './icons/dot.svg';
import Fire from './icons/fire.svg';
import SnowFlake01 from './icons/snowflake-01.svg';
import Sun from './icons/sun.svg';
import Wind02 from './icons/wind-02.svg';
import AIStars from './icons/ai-stars.svg';
import BrandedPage from './icons/branded-page.svg';
import GeneralPage from './icons/general-page.svg';
import TextA from './icons/text-a.svg';
import UserCheck01 from './icons/user-check-01.svg';
import PhoneOutgoing01 from './icons/phone-outgoing-01.svg';
import FileSearch02 from './icons/file-search-02.svg';
import DataFlow03 from './icons/dataflow-03.svg';
import Briefcase01 from './icons/briefcase-01.svg';
import SparkleSmall from './icons/sparkle-small.svg';
import Stars01Gray from './icons/stars-01-gray.svg';
import Stars01Colored from './icons/stars-01-colored.svg';
// Templates one/multi page icons
import OnePage from './icons/one-page.svg';
import MultiTabPage from './icons/multi-tab-page.svg';

// Call recordings
import HeadPhones02 from './icons/headphones-02.svg';
import ArrowOpenClose from './icons/arrow-open-close.svg';
import ArrowCalendar from './icons/arrow-calendar.svg';
import ZoomCall from './icons/zoom-call.svg';
import TeamsCall from './icons/teams-call.svg';
import Users02 from './icons/users-02.svg';
import Building from './icons/building.svg';

/**
 * Important: Use unique identifiers for SVG icons to avoid conflicts.
 */

export const IconMap = {
  ActionBannerIcon,
  AddColumn,
  AddFolder,
  AddRow,
  AdvancedEditor,
  AlertBarIcon,
  AlertCircle,
  AlertTriangle,
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  AlignVerBottom,
  AlignVerCenter,
  AlignVerTop,
  AnalyticsIcon,
  ArialFont,
  GoogleChrome,
  ArrowCircleRight,
  ArrowCircleRightGreen,
  ArrowCircleUp,
  ArrowCircleDown,
  ArrowCircleUpRight,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowSquareUpRight,
  Attachment02,
  Film02,
  FilmArrow,
  FilmCross,
  Avatar,
  BackArrow,
  BarChart01,
  BarChartSquare01,
  BitterFont,
  Bold,
  BoldSquare,
  BookClosed,
  BrowserWebGraphAnalytics,
  BlankPageSquare,
  Building,
  Calendar,
  CalendarPlus01,
  CalendlyLogo,
  CalendlyLogoSm,
  CellEdit,
  ChatMessage,
  Check,
  CheckCircle,
  CheckCircleGreen,
  Checked,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChiliPiperLogo,
  ChiliPiperLogoSm,
  ClockStopwatch,
  CloudOffIcon,
  ColorCheck,
  ColorPalette,
  Conversion,
  Cross,
  CrossCircleRed,
  CrossClose,
  CrossedEye,
  CursorButton,
  CursorClick02,
  DancingScriptFont,
  Delete,
  DesktopIcon,
  DistributeLogo,
  DistributeLogoLetter,
  Document,
  Document01,
  DotsHorizontal,
  DotsVertical,
  DotsVerticalBold,
  Drag,
  DroidSansFont,
  DropCross,
  Duplicate,
  Edit02,
  Edit05,
  Edit2Icon,
  Edit04,
  EditIcon,
  EmailSymbol,
  Eraser,
  EnterKey,
  ExoFont,
  Eye,
  FigtreeFont,
  File02,
  File04,
  File05,
  FileDocument,
  FilterFunnel02,
  FlexAlignBottom,
  GatedContentIcon,
  GeorgiaFont,
  GreenCheckMark,
  Gmail1,
  Gmail2,
  GongLg,
  Globe03,
  Globe04,
  Headings,
  HelpCircle,
  Hourglass03,
  Hubspot,
  Image01,
  Image05,
  ImagePlus,
  ImageIndentLeft,
  ImageIndentRight,
  ImageX,
  InconsolataFont,
  Info,
  InterFont,
  Italic,
  LatoFont,
  LayersThree01,
  LayoutAlt03,
  LayoutBottom,
  LayoutLeft,
  LayoutTop,
  LeftIndent03,
  Letter,
  LifeBuoy,
  Link,
  Link02,
  LinkExternal,
  ListBulleted,
  ListCheckMark,
  ListNumbered,
  Lock01,
  Logout,
  LogOut02,
  Logotype,
  LongText,
  MagicWand,
  MagicWand01,
  Mail01,
  Mail02,
  Menu02,
  Menubar,
  MenuCopy,
  MenuEdit,
  MenuEye,
  MenuFolder,
  MenuLink,
  LinkBroken,
  MergeCells,
  MerriWeatherFont,
  MessageDotsCircle,
  Mic,
  MobileIcon,
  MontserratFont,
  MoveToFolder,
  NavMenuButton,
  OpenSansFont,
  OswaldFont,
  PalationFont,
  Paperclip02,
  Paragraphs,
  PDFIcon,
  Phone,
  PhotoCenter,
  PhotoLeft,
  PhotoPictureUser,
  PhotoRight,
  Picture,
  Plus,
  PlusCircle,
  PlusSquare,
  SmallPlus,
  Building07,
  PopupIcon,
  PTSansFont,
  PTSerifFont,
  PuzzlePiece01,
  PuzzlePiece02,
  PoweredByDistribute,
  Quote,
  RefreshCcw,
  RefreshCcw04,
  RefreshCcw05,
  RefreshCw05,
  RemoveColumn,
  RemoveRow,
  ReverseLeft,
  ReverseRight,
  RobotoFont,
  RollerBrush,
  Rows01,
  RubikFont,
  Salesforce,
  SearchCheck,
  SearchSm,
  SearchMd,
  Settings,
  Settings01,
  Settings02,
  ShortText,
  Sidebar,
  SpacingVertical,
  SplitCell,
  SquareIcon,
  SqueezePageIcon,
  Star,
  Stars02,
  Stars03,
  StopCircle,
  Strikethrough,
  Summary,
  SwitchHorizontal01,
  SwitchVertical01,
  SystemUIFont,
  Share07,
  Star06,
  Table,
  TableIcon,
  TabletIcon,
  TabSymbol,
  TahomaFont,
  Target04,
  Template,
  TextLines,
  TimesNewRomanFont,
  TiptapCallout,
  TiptapDivider,
  TiptapFile,
  TiptapImage,
  TiptapTable,
  TiptapVideo,
  Tiptap2Columns,
  Tiptap3Columns,
  Tiptap4Columns,
  ToolbarSettings,
  TooltipArrow,
  Trash,
  TrebuchetMSFont,
  Type01,
  TypeSquare,
  TypeStrikethrough,
  UbuntuFont,
  Underline,
  Upload,
  UploadCloud,
  UploadCloud02,
  User03,
  UserPlus,
  UserCircle,
  Users01,
  Users02,
  VerdanaFont,
  VideoRecorder,
  VollkornFont,
  XCircle,
  XClose,
  Youtube,
  CheckGreenBg,
  ChevronSelectorVertical,
  BulletListSlash,
  NumberedListSlash,
  QuoteSlash,
  Linkedin,
  LinkedinDisabled,
  Facebook,
  Twitter,
  TwitterDisabled,
  H1,
  H2,
  H3,
  Zapier,
  Rocket02,
  Party,
  Placeholder,
  PlayCircle,
  ToggleListSlash,
  ToggleIcon,
  Tag02,
  CreditCardRefresh,
  VisaLogo,
  UnionPayLogo,
  MastercardLogo,
  JcbLogo,
  DinnersLogo,
  AmexLogo,
  UnknownCardBrand,
  StripeText,
  CreditCard02,
  InfoCircle,
  Sale03,
  CheckVerified01,
  ArrowNarrowRight,
  Google,
  GoogleDrive,
  Microsoft,
  Loom,
  Calendly,
  WorkspaceSettings,
  Palette,
  Members,
  Award,
  PlusLight,
  PieChartFilled,
  Lead,
  RecentViewers,
  DownloadCloud01,
  Download,
  Download02,
  Copy01,
  Copy03,
  Copy07,
  ChevronSelectorVerticalBold,
  Film,
  Files,
  Templates,
  UserSquare,
  AnalyticsChart,
  Users,
  Lock,
  LockFilled,
  User,
  TimeLoadingBack,
  Trophy,
  PrimaryCircleLines,
  Share06,
  BgSquare,
  SearchLg,
  TeamAccess,
  EyeCrossedBold,
  EyeBold,
  DraftPencil,
  SearchRefraction,
  TemplatesPlus,
  Laptop01,
  Tablet01,
  Phone01,
  TiptapTodoList,
  TiptapButton,
  SlashCircle01,
  TiptapEmbedContent,
  TiptapCodeBlock,
  SharePublicly,
  InviteMembers,
  Export,
  Embed,
  VerifiedCheck,
  Play,
  Pause,
  Stop,
  Restart,
  Cancel,
  RecordingStart,
  Recording,
  Microphone,
  Microphone1,
  Microphone02,
  Camera,
  Shield02,
  Snippets,
  LayoutAlt02,
  Shape01,
  Shape02,
  SnippetText,
  SnippetImage,
  SnippetVideo,
  SnippetFile,
  FaceSmile,
  Paint,
  PlaySquare,
  Records,
  Heading1,
  Heading2,
  Heading3,
  BulletList,
  NumberList,
  ToggleList,
  CheckSquare,
  Blockquote,
  Announcement01,
  Divider,
  ButtonClick,
  ShortcutEnter,
  CodeSnippet02,
  Columns02,
  Columns03,
  Columns04,
  CalendlyLogoCurrent,
  PlayVideo,
  Grid02,
  WizardWrite,
  SnippetMenuText,
  SnippetMenuImg,
  SnippetMenuVideo,
  ArrowsLeftRight,
  GoogleDrive1,
  Route,
  Dot,
  Fire,
  SnowFlake01,
  Sun,
  Wind02,
  ClipboardCheck,
  UserBadge,
  AtSign,
  Globe01,
  XSocial,
  FacebookLine,
  LinkedInLine,
  ArrowSquareLeft,
  AIStars,
  CheckCircleBroken,
  PlaceholderDashed,
  BrandedPage,
  GeneralPage,
  TextA,
  UserCheck01,
  PhoneOutgoing01,
  FileSearch02,
  DataFlow03,
  Briefcase01,
  OnePage,
  SparkleSmall,
  Stars01Gray,
  Stars01Colored,
  MultiTabPage,
  Expand01,
  HeadPhones02,
  GoogleMeet,
  ArrowOpenClose,
  ArrowCalendar,
  ZoomCall,
  TeamsCall,
} as const;
