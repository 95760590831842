import { useCreatePageStepContext } from '../context';
import { PageAppearance } from './components/PageAppearance';
import {
  BattleCard,
  BusinessCase,
  CallFollowUp,
  CustomerOnboarding,
  FileSource,
  GongSource,
  LinkSource,
  MutualActionPlan,
  Other,
  QuarterlyBusinessReview,
  SelectPageType,
  TextSource,
} from './SidebarElements';
import { IntermediateStep } from './IntermediateStep';
import { CreateOutline } from './Outline';
import { CreatePageActiveScreenEnum } from '../../../config/types';
import { useSidebarActiveScreen } from '../useSIdebarActiveScreen';
export const Sidebar = () => {
  const { activeScreen } = useCreatePageStepContext();

  useSidebarActiveScreen();

  return (
    <>
      <PageAppearance
        title="General page"
        description="Blank page without logos. Use it for general pages or to showcase your creativity with standard editor features."
        imageSrc="/assets/images/general-page-appearance.svg"
        isVisible={activeScreen === CreatePageActiveScreenEnum.GeneralPage}
      />
      <PageAppearance
        title="Branded page"
        description="Automatically generated with branded graphics like cover and logos from your target company."
        imageSrc="/assets/images/branded-page-appearance.svg"
        isVisible={activeScreen === CreatePageActiveScreenEnum.BrandedPage}
      />
      <SelectPageType
        isVisible={activeScreen === CreatePageActiveScreenEnum.SelectType}
      />
      <BusinessCase
        isVisible={activeScreen === CreatePageActiveScreenEnum.BusinessCaseType}
      />
      <CallFollowUp
        isVisible={activeScreen === CreatePageActiveScreenEnum.CallFollowUpType}
      />
      <BattleCard
        isVisible={activeScreen === CreatePageActiveScreenEnum.BattleCardType}
      />
      <MutualActionPlan
        isVisible={
          activeScreen === CreatePageActiveScreenEnum.MutualActionPlanType
        }
      />
      <CustomerOnboarding
        isVisible={
          activeScreen === CreatePageActiveScreenEnum.CustomerOnboardingType
        }
      />
      <QuarterlyBusinessReview
        isVisible={activeScreen === CreatePageActiveScreenEnum.QBRType}
      />
      <Other
        isVisible={activeScreen === CreatePageActiveScreenEnum.OtherType}
      />
      <TextSource
        isVisible={activeScreen === CreatePageActiveScreenEnum.TextSource}
      />
      <IntermediateStep
        isVisible={activeScreen === CreatePageActiveScreenEnum.IntermediateStep}
      />
      <LinkSource
        isVisible={activeScreen === CreatePageActiveScreenEnum.LinkSource}
      />
      <CreateOutline
        isVisible={activeScreen === CreatePageActiveScreenEnum.CreateOutline}
      />
      <GongSource
        isVisible={activeScreen === CreatePageActiveScreenEnum.GongCallSource}
      />
      <FileSource
        isVisible={activeScreen === CreatePageActiveScreenEnum.FileSource}
      />
    </>
  );
};
