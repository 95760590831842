import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';
import {
  ActiveAIStepType,
  ChosenFolderData,
  CreatePageAction,
  GenerateOutlineAction,
  GenerateTabWithAIAction,
  InitializePageGenerationWithAIAction,
  PageCreationFlowStepsEnum,
  PageCreationTypesEnum,
  ParseFileAction,
  ParseLinkAction,
  StartPageCreationFlowAction,
} from '../config/types';
import {
  ContextType,
  GeneratedOutlineType,
} from '@distribute/shared/api-types/create-with-ai';

type State = {
  isPageCreationModalOpen: boolean;
  isChooseFolderModalOpen: boolean;
  pageCreationType: PageCreationTypesEnum;
  currentStep: PageCreationFlowStepsEnum;
  chosenFolderData: ChosenFolderData | null;
  createPageIsLoading: boolean;
  preselectedGongCallTranscriptId: string | null;
  activeAIStep: ActiveAIStepType | null;
  isGenerateOutlineLoading: boolean;
  isGeneratePageLoading: boolean;
  wasOutlineGenerationStarted: boolean;
  generatedOutline: GeneratedOutlineType;
  embeddedContext: ContextType | null;
  contextRawData: string[] | null;
};

const initialState: State = {
  isPageCreationModalOpen: false,
  isChooseFolderModalOpen: false,
  pageCreationType: PageCreationTypesEnum.FROM_SCRATCH,
  currentStep: PageCreationFlowStepsEnum.GET_STARTED,
  chosenFolderData: null,
  createPageIsLoading: false,
  preselectedGongCallTranscriptId: null,
  activeAIStep: ActiveAIStepType.APPEARANCE,
  isGenerateOutlineLoading: false,
  isGeneratePageLoading: false,
  wasOutlineGenerationStarted: false,
  generatedOutline: [],
  embeddedContext: null,
  contextRawData: null,
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'pageCreationFlow',
  initialState,
  reducers: {
    setPageCreationFlowModalOpen: (
      state,
      { payload: isPageCreationModalOpen }: PayloadAction<boolean>
    ) => ({ ...state, isPageCreationModalOpen }),
    setChooseFolderModalOpen: (
      state,
      { payload: isChooseFolderModalOpen }: PayloadAction<boolean>
    ) => ({ ...state, isChooseFolderModalOpen }),
    setPageCreationType: (
      state,
      { payload: pageCreationType }: PayloadAction<PageCreationTypesEnum>
    ) => ({ ...state, pageCreationType }),
    setCurrentStep: (
      state,
      { payload: currentStep }: PayloadAction<PageCreationFlowStepsEnum>
    ) => ({ ...state, currentStep }),
    setChosenFolderData: (
      state,
      { payload: chosenFolderData }: PayloadAction<ChosenFolderData | null>
    ) => ({ ...state, chosenFolderData }),
    setCreatePageIsLoading: (
      state,
      { payload: createPageIsLoading }: PayloadAction<boolean>
    ) => ({ ...state, createPageIsLoading }),
    setPreselectedGongCallTranscriptId: (
      state,
      { payload: preselectedGongCallTranscriptId }: PayloadAction<string | null>
    ) => ({ ...state, preselectedGongCallTranscriptId }),
    setActiveAIStep: (
      state,
      { payload: activeAIStep }: PayloadAction<ActiveAIStepType | null>
    ) => ({ ...state, activeAIStep }),
    setIsGenerateOutlineLoading: (
      state,
      { payload: isGenerateOutlineLoading }: PayloadAction<boolean>
    ) => ({ ...state, isGenerateOutlineLoading }),
    setIsGeneratePageLoading: (
      state,
      { payload: isGeneratePageLoading }: PayloadAction<boolean>
    ) => ({ ...state, isGeneratePageLoading }),
    setWasOutlineGenerationStarted: (
      state,
      { payload: wasOutlineGenerationStarted }: PayloadAction<boolean>
    ) => ({ ...state, wasOutlineGenerationStarted }),
    setGeneratedOutline: (
      state,
      { payload: generatedOutline }: PayloadAction<{ title: string }[]>
    ) => ({ ...state, generatedOutline }),
    setEmbeddedContext: (
      state,
      { payload: embeddedContext }: PayloadAction<ContextType | null>
    ) => ({ ...state, embeddedContext }),
    setContextRawData: (
      state,
      { payload: contextRawData }: PayloadAction<string[] | null>
    ) => ({ ...state, contextRawData }),
    reset: () => initialState,
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  openPageCreationFlow: createAction<StartPageCreationFlowAction>(
    'pageCreationFlow/startPageCreationFlow'
  ),
  closePageCreationFlow: createAction('pageCreationFlow/closePageCreationFlow'),
  closeChooseFolderModal: createAction(
    'pageCreationFlow/closeChooseFolderModal'
  ),
  createPage: createAction<CreatePageAction>('pageCreationFlow/createPage'),
  parseFileTextContent: createAction<ParseFileAction>(
    'pageCreationFlow/parseFileTextContent'
  ),
  parseLinkTextContent: createAction<ParseLinkAction>(
    'pageCreationFlow/parseLinkTextContent'
  ),
  generateOutline: createAction<GenerateOutlineAction>(
    'pageCreationFlow/generateOutline'
  ),
  initializePageGenerationWithAI:
    createAction<InitializePageGenerationWithAIAction>(
      'pageCreationFlow/initializePageGenerationWithAI'
    ),
  generateTab: createAction<GenerateTabWithAIAction>(
    'pageCreationFlow/generateTab'
  ),
};
