import { Recall } from './recall';
import { CamelCase, KeysToCamelCase } from './shared';

export type MeetingCalendarPlatform = CamelCase<Recall.CalendarPlatform>;
export type MeetingPlatform = CamelCase<Recall.MeetingPlatform>;
export type MeetingCalendarStatus = Recall.CalendarStatus;

export type MeetingCalendar = {
  id: string;
  userToTeamId: number | null;

  recallCalendarId: string;

  platform: MeetingCalendarPlatform;
  oauthEmail: string;
  platformEmail: string | null;
  status: MeetingCalendarStatus;

  createdAt: Date;
  updatedAt: Date;
};

export type MeetingCalendarConnected = {
  id: string;
  userToTeamId: number;

  recallCalendarId: string;

  platform: CamelCase<Recall.CalendarPlatform>;
  oauthEmail: string;
  platformEmail: string;
  status: 'connected';

  createdAt: Date;
  updatedAt: Date;
};

export type MeetingCalendarEventAttendee = { email: string; accepted: boolean };

export type MeetingCalendarEventData = {
  attendees: MeetingCalendarEventAttendee[];
  bots: KeysToCamelCase<Recall.EventBot>[];
};

export type MeetingCalendarEvent = {
  id: string;
  recallEventId: string;
  recallCalendarId: string;
  meetingCalendarId: string;
  shouldRecord: boolean | null;
  isDeleted: boolean;
  isPrivate: boolean | null;
  isExternal: boolean;
  isOrganizedByUser: boolean;
  name: string;
  platform: MeetingCalendarPlatform;
  meetingPlatform: MeetingPlatform | null;
  meetingUrl: string | null;
  eventData: MeetingCalendarEventData;
  startTime: Date;
  endTime: Date;
  createdAt: Date;
  updatedAt: Date;
};

export type MeetingCalendarEventConnected = MeetingCalendarEvent & {
  meetingCalendar: MeetingCalendarConnected;
};

export type MeetingManualEvent = {
  id: string;
  userToTeamId: number;
  shouldRecord: boolean | null;
  isPrivate: boolean | null;
  startTime: Date;
  endTime: Date;
  name: string;
  meetingPlatform: MeetingPlatform;
  meetingUrl: string;
  botId: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export enum MeetingEventType {
  MANUAL = 'manual',
  CALENDAR = 'calendar',
}
