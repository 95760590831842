import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { object, string, number } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Modal } from '../../../shared/ui';
import { ManuallyJoinCallData } from '../model/types';
import { callRecordingsModel } from '../model';

const validationSchema = object().shape({
  name: string().trim().required('This is required field.'),
  meetingUrl: string()
    .trim()
    .url('Please enter a valid join URL.')
    .required('This is required field.'),
  duration: number()
    .nullable()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value
    )
    .required('This is required field.')
    .typeError('Please enter a valid number.')
    .min(5, 'Value must be more than or equal to 5.')
    .max(120, 'Value must be less than or equal to 120.'),
});

export const JoinCallModal = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ManuallyJoinCallData>({
    resolver: yupResolver(validationSchema),
  });

  const isJoinCallModalOpen = useSelector(
    callRecordingsModel.selectors.selectIsJoinCallModalOpen
  );
  const isJoinCallInProgress = useSelector(
    callRecordingsModel.selectors.selectIsJoinCallInProgress
  );

  const handleCloseJoinCallModal = () => {
    dispatch(callRecordingsModel.actions.setIsJoinCallOpenModal(false));
  };

  const handleFormSubmit = (data: ManuallyJoinCallData) => {
    dispatch(callRecordingsModel.actions.addManuallyJoinCall(data));
  };

  return (
    <Modal
      isOpen={isJoinCallModalOpen}
      onClose={handleCloseJoinCallModal}
      title="Join Call Now"
      className="w-100"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          loading={isJoinCallInProgress}
        >
          Join now
        </Button>
      }
    >
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="flex flex-col gap-3.75"
      >
        <Input
          type="text"
          autoComplete="off"
          label="Meeting name *"
          placeholder="Enter meeting name"
          {...register('name')}
          isError={!!errors.name}
          messageText={errors.name?.message}
        />
        <Input
          type="text"
          autoComplete="off"
          label="Join URL *"
          placeholder="Enter join url"
          {...register('meetingUrl')}
          isError={!!errors.meetingUrl}
          messageText={errors.meetingUrl?.message}
        />
        <Input
          type="text"
          autoComplete="off"
          label="Call duration *"
          placeholder="Enter call duration"
          {...register('duration')}
          isError={!!errors.duration}
          messageText={errors.duration?.message}
        />
      </form>
    </Modal>
  );
};
