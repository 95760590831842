import React from 'react';
import { CreatePageStepLeftWrapper } from './components/CreatePageStepLeftWrapper';
import { PageAppearance } from './components/PageAppearance';
import { Button } from '../../../../../shared/ui';
import { useFormContext } from 'react-hook-form';
import {
  CreatePageFormSchemaType,
  PageCreationFlowStepsEnum,
} from '../../../config/types';
import { useDispatch, useSelector } from 'react-redux';
import { pageCreationFlowModel } from '../../../model';
import { IconMap } from '../../../../../shared/sprite';

const Footer = () => {
  const dispatch = useDispatch();
  const { watch } = useFormContext<CreatePageFormSchemaType>();
  const isBranded = watch('isBranded');
  const brandCompany = watch('brandCompany');
  const title = watch('pageTitle');

  const isLoading = useSelector(
    pageCreationFlowModel.selectors.selectCreatePageIsLoading
  );

  const createPage = () => {
    dispatch(
      pageCreationFlowModel.actions.createPage({
        title,
        brandLogo: brandCompany?.logo ?? null,
        brandCompanyDomain: brandCompany?.domain ?? null,
      })
    );
  };

  const goBack = () => {
    dispatch(
      pageCreationFlowModel.actions.setCurrentStep(
        PageCreationFlowStepsEnum.TEMPLATES
      )
    );
  };

  return (
    <div className="flex items-center gap-2">
      <Button
        variant="icon-text"
        color="secondary"
        size="xl"
        onClick={goBack}
        iconLeftName={IconMap.ArrowLeft}
        iconLeftWidth={20}
      >
        Back
      </Button>
      <Button
        variant="text"
        color="primary"
        disabled={isBranded ? !brandCompany : false}
        className="w-full"
        size="xl"
        onClick={createPage}
        loading={isLoading}
      >
        Create page
      </Button>
    </div>
  );
};

export const CreateFromTemplate: React.FC = () => {
  return (
    <CreatePageStepLeftWrapper footer={<Footer />}>
      <PageAppearance />
    </CreatePageStepLeftWrapper>
  );
};
