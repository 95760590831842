import { api } from './instance';
import {
  CallsQueryParams,
  GetPaginatedCallsResponse,
  UpdateCallRequest,
  GetCallsCountResponse,
  GetCallDetailResponse,
} from '@distribute/shared/api-types/call';

export const callApi = {
  async getCalls(query: CallsQueryParams) {
    const { data: responseData } = await api.get('calls', {
      params: query,
    });
    return responseData as GetPaginatedCallsResponse;
  },

  async getCallsCount(query: {
    teamId: number;
  }): Promise<GetCallsCountResponse> {
    const { data: responseData } = await api.get('calls/count', {
      params: query,
    });

    return responseData as GetCallsCountResponse;
  },

  async updateCallItem(
    id: string,
    data: UpdateCallRequest,
    params: { teamId: number }
  ) {
    const { data: responseData } = await api.patch(`calls/${id}`, data, {
      params,
    });
    return responseData;
  },

  async deleteItem(id: string, params: { teamId: number }) {
    await api.delete(`calls/${id}`, { params });
  },

  async getCallDetails(id: string, params: { teamId: number }) {
    const { data: responseData } = await api.get(`calls/${id}`, {
      params,
    });
    return responseData as GetCallDetailResponse;
  },
};
