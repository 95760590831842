import { RootState } from '../../../app';

export const selectPageCreationType = ({ pageCreationFlow }: RootState) =>
  pageCreationFlow.pageCreationType;

export const selectPageCreationFlowModalOpen = ({
  pageCreationFlow,
}: RootState) => pageCreationFlow.isPageCreationModalOpen;

export const selectChooseFolderModalOpen = ({ pageCreationFlow }: RootState) =>
  pageCreationFlow.isChooseFolderModalOpen;

export const selectCurrentStep = ({ pageCreationFlow }: RootState) =>
  pageCreationFlow.currentStep;

export const selectChosenFolderData = ({ pageCreationFlow }: RootState) =>
  pageCreationFlow.chosenFolderData;

export const selectCreatePageIsLoading = ({ pageCreationFlow }: RootState) =>
  pageCreationFlow.createPageIsLoading;

export const selectPreselectedGongCallTranscriptId = ({
  pageCreationFlow,
}: RootState) => pageCreationFlow.preselectedGongCallTranscriptId;

export const selectActiveAIStep = ({ pageCreationFlow }: RootState) =>
  pageCreationFlow.activeAIStep;

export const selectIsGenerateOutlineLoading = ({
  pageCreationFlow,
}: RootState) => pageCreationFlow.isGenerateOutlineLoading;

export const selectWasOutlineGenerationStarted = ({
  pageCreationFlow,
}: RootState) => pageCreationFlow.wasOutlineGenerationStarted;

export const selectGeneratedOutline = ({ pageCreationFlow }: RootState) =>
  pageCreationFlow.generatedOutline;

export const selectEmbeddedContext = ({ pageCreationFlow }: RootState) =>
  pageCreationFlow.embeddedContext;

export const selectContextRawData = ({ pageCreationFlow }: RootState) =>
  pageCreationFlow.contextRawData;

export const selectIsGeneratePageLoading = ({ pageCreationFlow }: RootState) =>
  pageCreationFlow.isGeneratePageLoading;
