import {
  takeEvery,
  takeLatest,
  call,
  put,
  debounce,
  all,
} from 'redux-saga/effects';
import { callRecordingSettingsModel } from '../../../features/call-recording-settings';
import { callRecordingsModel } from '../../../features/call-recordings';
import { callModel } from '../../../features/call';
import { fetchCalls } from '../../../features/call/model/sagas';

export function* callRecordingsWorker() {
  yield put(callModel.actions.reset());
  yield takeEvery(
    callRecordingsModel.actions.connectToGoogleCalendar,
    callRecordingSettingsModel.sagas.connectToGoogleCalendar
  );

  yield takeEvery(
    callRecordingsModel.actions.updateJoinPreference,
    callRecordingsModel.sagas.updateJoinPreference
  );

  yield takeEvery(
    callRecordingsModel.actions.toggleMeetingEventRecording,
    callRecordingsModel.sagas.updateMeetingEvent
  );

  yield takeEvery(
    callRecordingsModel.actions.toggleMeetingEventVisibility,
    callRecordingsModel.sagas.updateMeetingEvent
  );

  yield takeEvery(
    callRecordingsModel.actions.addManuallyJoinCall,
    callRecordingsModel.sagas.addManuallyJoinCall
  );

  yield takeLatest(callModel.actions.loadCalls, callModel.sagas.loadCalls);

  yield takeEvery(
    callModel.actions.deleteCallItem,
    callModel.sagas.deleteCallItem
  );

  yield takeEvery(
    callModel.actions.updateCallItem,
    callModel.sagas.updateCallItem
  );

  yield takeEvery(
    callRecordingSettingsModel.actions.connectToGoogleCalendar,
    callRecordingSettingsModel.sagas.connectToGoogleCalendar
  );

  yield all([
    call(callModel.sagas.getCallsCount),
    call(callModel.sagas.loadCalls),
    call(callRecordingsModel.sagas.loadMeetingEvents),
  ]);

  yield debounce(300, callModel.actions.searchCalls, fetchCalls);
}
