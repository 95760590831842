import { call, put, select } from 'redux-saga/effects';
import { pagesApi } from '../../../../shared/api/';
import {
  AnalyticsEvents,
  Folder,
  GettingStartedStep,
  Page,
  Template,
} from '@distribute/shared/types';
import { actions } from '../reducer';
import { logger } from '../../../../shared/lib';
import { redirect } from '../../../../entities/history';
import { handleError } from '../../../../utils/handleError';
import { analytics } from '../../../../entities/analytics';
import { brandfetchApi } from '../../../../shared/api/axios/brandfetch';
import { RT } from '../../../../shared/types';
import { DEFAULT_RATIO } from '@distribute/shared/utils';
import { recordingsModel } from '../../../../features/recordings';
import { getFolder } from '../../../../features/pages/model/sagas/getFolder';
import {
  setRecentTemplatesData,
  templatesModel,
} from '../../../../features/templates';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { generateColorsPalette } from '../../../../features/page-style/lib/generateColorsPalette';
import { pagesModel } from '../../../../features/pages';
import { foldersModel } from '../../../../features/folders';
import { gettingStartedModel } from '../../../../features/getting-started/model';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { GeneratedOutlineType } from '@distribute/shared/api-types/create-with-ai';

type Props = {
  title: string;
  brandLogo: string | null;
  brandCompanyDomain: string | null;
  folderId: string;
  folderName: string;
  isSeparateTabs?: boolean;
  generatedOutline?: GeneratedOutlineType;
};

export function* createPage({
  folderId,
  folderName,
  title,
  brandLogo,
  brandCompanyDomain,
  isSeparateTabs,
  generatedOutline,
}: Props) {
  try {
    yield put(actions.setCreatePageIsLoading(true));

    const videoRecording: RT<typeof recordingsModel.selectors.selectRecord> =
      yield select(recordingsModel.selectors.selectRecord);

    const currentFolderId: string = yield getFolder(folderId, folderName);

    const currentTemplate: Template = yield select(
      templatesModel.selectors.selectCurrentSelectedTemplate
    );

    const color: string = yield call(
      brandfetchApi.getBrandColor,
      brandCompanyDomain
    );

    yield put(editorSidebarModel.actions.setSidebarOpen(true));
    const page: Page = yield pagesApi.createPage({
      record: Object.keys(videoRecording).length
        ? {
            prefix: videoRecording.prefix,
            playbackId: videoRecording.playbackId,
            aspectRatio:
              videoRecording?.metadata?.mux?.aspectRatio || DEFAULT_RATIO,
          }
        : undefined,
      folderId: currentFolderId,
      title,
      templateId: currentTemplate?.id,
      brandLogo,
      brandCompanyDomain,
      brandColor: color ? generateColorsPalette(color) : undefined,
      predefinedTabs: isSeparateTabs ? generatedOutline : undefined,
    });

    if (currentTemplate) {
      yield call(setRecentTemplatesData);
    }

    const pages: Page[] = yield select(pagesModel.selectors.selectPages);
    yield put(pagesModel.actions.setPages([...pages, page]));

    const folders: Folder[] = yield select(
      foldersModel.selectors.selectFolders
    );
    const currentFolder = folders.find((i) => currentFolderId === i.id);
    yield put(foldersModel.actions.setCurrentFolder(currentFolder));
    yield call(redirect.toEditorPage, {
      sequenceNumber: page.sequenceNumber,
    });
    yield call(
      gettingStartedModel.sagas.onGettingStartedStepCompleted,
      GettingStartedStep.CREATE_PAGE
    );

    analytics.track(
      currentTemplate
        ? AnalyticsEvents.PAGE_CREATED_FROM_TEMPLATE
        : AnalyticsEvents.PAGE_CREATED_FROM_SCRATCH,
      {
        pageId: page.id,
        title: page.content.title || 'Untitled',
        slug: page.pageSlug,
      }
    );

    yield put(templatesModel.actions.setCurrentSelectedTemplate(undefined));
  } catch (e: unknown) {
    logger.error(e);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', handleError(e, 'Failed to create the page'))
      )
    );
  } finally {
    yield put(actions.setCreatePageIsLoading(false));
  }
}
