import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import {
  ConnectCalendarState,
  MeetingEventsSidebar,
  EmptyState,
  WalkthroughPopup,
  JoinCallModal,
  callRecordingsModel,
} from '../../../features/call-recordings';
import { callRecordingSettingsModel } from '../../../features/call-recording-settings';
import { callModel } from '../../../features/call';
import { Button, Link, Tooltip, Loader } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { navigate } from '../../../processes/navigation';
import { CallList } from '../../../features/call';
import { TEAM_SETTINGS_CALL_RECORDINGS } from '../../../entities/history';
import { CALL_RECORDING_WALKTHROUGH_POPUP_STORAGE_KEY } from '../../../shared/constants';

export const CallRecordingsPage = () => {
  const dispatch = useDispatch();
  const isAwareWalkthroughPopup = localStorage.getItem(
    CALL_RECORDING_WALKTHROUGH_POPUP_STORAGE_KEY
  );
  const [isWalkthroughPopupOpen, setIsWalkthroughPopupOpen] = useState(
    !isAwareWalkthroughPopup
  );
  const meetingsCalendar = useSelector(
    callRecordingSettingsModel.selectors.selectMeetingCalendarWithError
  );
  const callsCount = useSelector(callModel.selectors.selectTotal);
  const meetingEvents = useSelector(
    callRecordingsModel.selectors.selectMeetingEvents
  );
  const shouldShowCalls = callsCount.teamCalls > 0 || callsCount.userCalls > 0;
  const isConnectToCalendar = !meetingsCalendar && !shouldShowCalls;
  const isEmptyState = !shouldShowCalls && meetingsCalendar;
  const isCalendarSideBar =
    meetingsCalendar || shouldShowCalls || meetingEvents.length > 0;
  const isCallCountLoading = useSelector(
    callModel.selectors.selectIsCallCountLoading
  );

  const handleNavigateToSettings = () => {
    dispatch(navigate({ to: TEAM_SETTINGS_CALL_RECORDINGS }));
  };

  const handleCloseWalkthroughPopup = () => {
    setIsWalkthroughPopupOpen(false);
    localStorage.setItem(CALL_RECORDING_WALKTHROUGH_POPUP_STORAGE_KEY, 'true');
  };

  const handleJoinCallManually = () => {
    dispatch(callRecordingsModel.actions.setIsJoinCallOpenModal(true));
  };

  if (isCallCountLoading) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col min-w-0 flex-grow-1 pb-8">
      <Helmet titleTemplate="Call Recordings - Distribute" />

      <header className="flex justify-between gap-4 mb-5">
        <h1 className="font-medium text-gray-900 text-display-sm font-display">
          Call Recordings
        </h1>
        <div className="flex gap-2 items-center">
          <Button
            onClick={handleNavigateToSettings}
            variant="icon-text"
            color="secondary"
            iconLeftName={IconMap.Settings}
          >
            Settings
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleJoinCallManually}
          >
            Join Call
          </Button>
        </div>
      </header>

      <div className="flex flex-1 min-h-0 justify-between relative gap-6">
        <div className="flex-1">
          {isConnectToCalendar && (
            <div className="h-full flex justify-center">
              <ConnectCalendarState size="large" />
            </div>
          )}

          {isEmptyState && (
            <div className="h-full flex items-center justify-center">
              <EmptyState />
            </div>
          )}

          {shouldShowCalls && (
            <div className="flex flex-col h-full pt-3">
              <CallList />
            </div>
          )}
        </div>
        {isCalendarSideBar && <MeetingEventsSidebar />}
      </div>
      {isConnectToCalendar && (
        <footer>
          <div className="text-center">
            <Tooltip
              className="max-w-80"
              hideArrow
              trigger={
                <Link
                  size="large"
                  onClick={() => setIsWalkthroughPopupOpen(true)}
                >
                  Why Distribute need access to your calendar?
                </Link>
              }
            >
              <div className="text-xs p-3">
                <strong className="text-base-white font-semibold">
                  Why Distribute need access to your calendar?
                </strong>
                <p className="text-gray-300 font-medium pt-1">
                  Distribute accesses your calendar events with links and
                  displays them for you. You can then choose which events
                  you&apos;d like Distribute to join and record, giving you
                  complete control over the sessions you want recorded.
                </p>
              </div>
            </Tooltip>
          </div>
        </footer>
      )}
      {isWalkthroughPopupOpen && (
        <WalkthroughPopup onClose={handleCloseWalkthroughPopup} />
      )}
      <JoinCallModal />
    </div>
  );
};
