import { call, takeEvery, debounce } from 'redux-saga/effects';
import { loadCallDetails } from '../../../features/call/model/sagas';
import { matchParams } from '../../../shared/lib';
import { callModel } from '../../../features/call';

export function* callPageWorker(location: Location, route: string) {
  const { pathname } = location;
  const params: { id: string } | null = matchParams(pathname, route);

  if (params?.id) {
    yield call(loadCallDetails, params.id);
  }

  yield takeEvery(
    callModel.actions.updateCallItem,
    callModel.sagas.updateCallItem
  );

  yield takeEvery(
    callModel.actions.deleteCallItem,
    callModel.sagas.deleteCallItem
  );

  yield debounce(
    300,
    callModel.actions.updateCallItemWithDebounce,
    callModel.sagas.updateCallItem
  );
}
