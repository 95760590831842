import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Icon, SelectableInput } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { GetCallResponse } from '@distribute/shared/api-types/call';
import { navigate } from '../../../processes/navigation';
import { CALL_RECORDINGS_ROUTE } from '../../../entities/history';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { callModel } from '../model';
import { getMuxVideoDownloadByPolicy } from '@distribute/shared/utils';
import { authUserModel } from '../../../entities/auth-user';
import { DEFAULT_NAME } from '../../recordings/config';

type CallHeaderProps = {
  call: GetCallResponse;
};

export const CallHeader: React.FC<CallHeaderProps> = ({ call }) => {
  const dispatch = useDispatch();
  const { isPrivate, name, userToTeam } = call;
  const owner = userToTeam.user;
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const isOwner = currentUser.id === owner.id;

  const handleBack = () => {
    dispatch(
      navigate({
        to: CALL_RECORDINGS_ROUTE,
      })
    );
  };

  const toggleVisibility = () => {
    if (!isPrivate) {
      dispatch(callModel.actions.setTogglingCallItem(call));
    } else {
      dispatch(
        callModel.actions.updateCallItem({
          id: call.id,
          data: { isPrivate: !isPrivate },
        })
      );
    }
  };
  const handleDownloadVideo = () => {
    const url = getMuxVideoDownloadByPolicy(call.muxAsset);
    window.open(url, '_blank');
  };

  const handleDelete = () => {
    dispatch(callModel.actions.setDeletingCallItem(call));
  };

  const handleNameUpdate = (name: string) => {
    dispatch(
      callModel.actions.updateCallItemWithDebounce({
        id: call.id,
        data: { name },
      })
    );
  };

  const callControls = [
    {
      label: isPrivate ? 'Make public' : 'Make private',
      id: 'public',
      iconName: IconMap.Lock01,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: toggleVisibility,
      hidden: !isOwner,
    },
    {
      label: 'Download video',
      id: 'rename',
      isSeparatedFromTop: isOwner,
      iconName: IconMap.Download02,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: handleDownloadVideo,
    },
    {
      label: 'Delete',
      id: 'delete',
      iconName: IconMap.Trash,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: handleDelete,
      hidden: !isOwner,
    },
  ].filter((control) => !control.hidden);

  return (
    <header className="flex justify-between items-center px-4 pl-3 py-3 sm:py-2 bg-base-white border-b border-gray-200 transition-all h-16">
      <div className="flex items-center gap-3 flex-1">
        <button type="button" onClick={handleBack}>
          <Icon glyph={IconMap.ChevronLeft} width={20} />
        </button>
        <span className="border-r border-base-black/10 h-5" />
        <SelectableInput
          variant="title"
          readonly={!isOwner}
          valueReadonly={name || DEFAULT_NAME}
          value={name}
          onChange={handleNameUpdate}
          placeholder="Untitled"
          tooltipText="Rename call"
          classNames="w-full max-w-120"
        />
      </div>
      <div className="flex items-center gap-2">
        <Dropdown
          align="end"
          listStyles="shadow-lg"
          isModalMode={false}
          triggerComponent={
            <DropdownMenu.Trigger
              className="flex items-center justify-center w-10 h-10
                border border-gray-200 rounded-lg focus:outline-none hover:border-gray-300
                bg-base-white/70 hover:bg-base-white"
            >
              <Icon glyph={IconMap.DotsVertical} width={20} />
            </DropdownMenu.Trigger>
          }
          items={callControls}
        />
        {/*<Button disabled size="md" variant="icon-text" color="primary" iconLeftName={IconMap.Plus} >*/}
        {/*  Create page*/}
        {/*</Button>*/}
      </div>
    </header>
  );
};
