import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTiptapEditor } from '../../../lib';
import { JSONContent, generateJSON } from '@tiptap/react';
import { EditorWritingMode } from '../types';
import { Selection } from './useEditorContextSelection';
import { logger } from '../../../../../shared/lib';
import { normalizeWritingContent } from '../lib';
import { DocumentContentItem } from '@distribute/shared/types';
import { jsonrepair } from 'jsonrepair';
import { useSelector } from 'react-redux';
import { aiModel } from '../../../../../features/ai';
import { pagesModel } from '../../../../../features/pages';

type StructuredContentSections = {
  [key: string]: { content: string };
};

type Props = {
  selectionFromRef: MutableRefObject<number>;
  selectionToRef: MutableRefObject<number>;
  setSelection: (selection: Partial<Selection>) => void;
  resultText: string;
  editorWritingMode: EditorWritingMode;
  setFocus: () => void;
  setSearchValue: (val: string) => void;
  contentItems?: DocumentContentItem[];
  setCurrentContentItem: (contentItem: DocumentContentItem) => void;
};

export const useEditorWriting = ({
  resultText,
  editorWritingMode,
  selectionToRef,
  selectionFromRef,
  setSelection,
  setFocus,
  setSearchValue,
  contentItems,
  setCurrentContentItem,
}: Props) => {
  const { editor } = useTiptapEditor();
  const [currentRequestContent, setCurrentRequestContent] =
    useState<JSONContent | null>(null);
  const checkForEmptyContent = useRef(false);

  const isGeneratingMultiTabs = useSelector(
    aiModel.selectors.selectIsGeneratingMultiTabs
  );
  const isStructuredResponse = useSelector(
    aiModel.selectors.selectIsStructuredResponse
  );
  const isLoading = useSelector(aiModel.selectors.selectIsLoading);
  const currentPageContentId = useSelector(
    pagesModel.selectors.selectCurrentContentId
  ) as number;
  const currentTab = contentItems?.find((el) => el.id === currentPageContentId);

  const from = selectionFromRef.current;
  const to = selectionToRef.current;

  const insertContent = useCallback(
    (content: JSONContent | null, replaceSelection?: boolean) => {
      if (!editor) return;

      if (replaceSelection) {
        editor.commands.replaceSelection({ from, to, content });
      } else {
        editor
          .chain()
          .insertContentAt(to, content, { updateSelection: true })
          .run();
      }
    },
    [editor, from, to]
  );

  const handleContentInsertion = useCallback(
    (content: JSONContent) => {
      if (!editor) return;

      if (editorWritingMode === EditorWritingMode.Editor) {
        editor.storage.ai.isShowInline = false;
        insertContent(content, true);
        setSelection({ to: editor.state.selection.to });
      } else {
        setCurrentRequestContent(content);
        setSelection({ to: editor.state.selection.to });
      }
    },
    [editor, editorWritingMode, insertContent, setSelection]
  );

  const processContent = useCallback(
    (rawContent: string) => {
      if (!editor) return;
      const normalizedContent = normalizeWritingContent(
        generateJSON(rawContent, editor.extensionManager.extensions)
      );
      handleContentInsertion(normalizedContent);
    },
    [editor, handleContentInsertion]
  );

  const goToNextTab = useCallback(
    (sections: StructuredContentSections) => {
      if (!currentTab) return;

      const sectionsNames = Object.keys(sections);
      if (
        currentTab.name !== sectionsNames[sectionsNames.length - 1] &&
        contentItems?.length
      ) {
        const nextTab = contentItems.find(
          (item) => item.name === sectionsNames[sectionsNames.length - 1]
        );
        if (nextTab) {
          setCurrentContentItem(nextTab);
          checkForEmptyContent.current = true;
        }
      }
    },
    [currentTab?.name, setCurrentContentItem]
  );

  const processStructuredContent = useCallback(
    (sections: StructuredContentSections) => {
      if (!editor) return;

      if (isGeneratingMultiTabs) {
        if (!currentTab || !Object.keys(sections).length) return;

        if (
          checkForEmptyContent.current &&
          editor.getHTML().trim() !== '<p></p>'
        ) {
          return;
        }
        checkForEmptyContent.current = false;

        const content = sections[currentTab.name]?.content;
        if (!content) return;

        processContent(content);

        goToNextTab(sections);
      } else {
        const combinedContent = Object.values(sections)
          .map((item) => item.content)
          .join('');
        processContent(combinedContent);
      }
    },
    [
      editor,
      isGeneratingMultiTabs,
      currentTab?.name,
      processContent,
      goToNextTab,
    ]
  );

  const handleResultText = useCallback(() => {
    if (!editor || !isLoading || !resultText.trim()) return;

    try {
      if (isStructuredResponse) {
        const repairedContent = jsonrepair(resultText);
        if (!repairedContent) return;

        const sections = JSON.parse(
          repairedContent
        ) as StructuredContentSections;
        if (!sections) return;

        processStructuredContent(sections);
      } else {
        processContent(resultText);
      }

      setFocus();
      setSearchValue('');
    } catch (e) {
      logger.error(e);
    }
  }, [
    editor,
    isLoading,
    resultText,
    isStructuredResponse,
    processStructuredContent,
    processContent,
    setFocus,
    setSearchValue,
  ]);

  useEffect(() => {
    handleResultText();
  }, [handleResultText]);

  return {
    currentRequestContent,
    onInsertEditorContent: insertContent,
  };
};
