import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetCallDetailResponse } from '@distribute/shared/api-types/call';
import { Player } from '@distribute/frontend/ui/player';
import {
  getMuxAssetImageByPolicy,
  getMuxAssetStreamByPolicy,
} from '@distribute/shared/utils';
import {
  Avatar,
  Icon,
  SelectableInput,
  TabContent,
  Tabs,
  TimeAgo,
} from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { CallSummary } from './CallSummary';
import { CallTranscript } from './CallTranscript';
import { DEFAULT_NAME } from '../../recordings/config';
import { authUserModel } from '../../../entities/auth-user';
import { callModel } from '../model';

type CallBLAProps = {
  call: GetCallDetailResponse;
};

const MeetingPlatforms = {
  googleMeet: {
    name: 'On Google Meet',
    icon: IconMap.GoogleMeet,
  },
  zoom: {
    name: 'on Zoom',
    icon: IconMap.ZoomCall,
  },
  teams: {
    name: 'on Teams',
    icon: IconMap.TeamsCall,
  },
} as const;

const TabName = {
  summary: 'summary',
  transcript: 'transcript',
};

const tabs: TabContent[] = [
  {
    name: TabName.summary,
    title: 'Summary',
    icon: IconMap.Document01,
  },
  {
    name: TabName.transcript,
    title: 'Transcript',
    icon: IconMap.Microphone02,
  },
];

export const CallDetails: React.FC<CallBLAProps> = ({ call }) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<TabContent>(tabs[0]);
  const { muxAsset, userToTeam, isPrivate, createdAt, platform, attendees } =
    call;

  const assetImagePath = getMuxAssetImageByPolicy(muxAsset);
  const videoUrl = getMuxAssetStreamByPolicy(muxAsset);
  const aspectRatio = muxAsset.data.aspectRatio;
  const owner = userToTeam.user;
  const currentUser = useSelector(authUserModel.selectors.selectUserWithError);
  const isOwner = currentUser.id === owner.id;
  const currentPlatform = MeetingPlatforms[platform];

  const handleNameUpdate = (name: string) => {
    dispatch(
      callModel.actions.updateCallItemWithDebounce({
        id: call.id,
        data: { name },
      })
    );
  };

  return (
    <div className="flex flex-col gap-6 max-w-5xl mx-auto w-full">
      <header className="flex flex-col gap-2">
        <SelectableInput
          variant="titleXl"
          readonly={!isOwner}
          valueReadonly={call.name || DEFAULT_NAME}
          value={call.name}
          onChange={handleNameUpdate}
          placeholder="Untitled"
          tooltipText="Rename call"
          classNames="w-full truncate"
        />
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-2">
            {owner && (
              <div className="flex gap-2 items-center text-sm text-gray-600 ">
                <Avatar
                  src={owner.photoUrl}
                  displayName={owner.displayName}
                  size="sm"
                />
                <span className="font-medium">{owner.displayName}</span>
                {!isPrivate && (
                  <>
                    <Icon glyph={IconMap.Dot} width={5} />
                    <p className="flex items-center gap-1">
                      <Icon glyph={IconMap.Users01} width={16} />
                      Team access
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <span className="shadow-xs">
              <Icon glyph={currentPlatform.icon} width={20} />
            </span>
            <span className="text-sm text-gray-600">
              {currentPlatform.name}
            </span>
            <Icon glyph={IconMap.Dot} width={5} />
            <TimeAgo datetime={createdAt} locale="my" />
          </div>
        </div>
      </header>
      <section className="flex gap-6">
        <div className="flex flex-col gap-6 max-w-160 w-full">
          <div className="border border-base-black-8 rounded-lg overflow-hidden min-h-90">
            <Player
              url={videoUrl}
              posterUrl={assetImagePath}
              aspectRatio={aspectRatio}
              removeDownloadOverlay
            />
          </div>
          <div>
            <Tabs
              className="!mb-4"
              tabs={tabs}
              onSelectTab={setSelectedTab}
              selectedTab={selectedTab}
            >
              {selectedTab.name === TabName.summary && (
                <CallSummary call={call} />
              )}

              {selectedTab.name === TabName.transcript && (
                <CallTranscript call={call} />
              )}
            </Tabs>
          </div>
        </div>
        <aside className="w-90">
          <div className="bg-gray-100 rounded-lg p-5">
            <h3 className="flex items-center gap-1.5 text-lg font-semibold">
              Participants
              <span className="border border-gray-200 bg-gray-50 rounded-full px-2 py-0.5 text-gray-700 text-xs font-medium">
                {attendees.length}
              </span>
            </h3>
            {attendees.length > 0 && (
              <ul>
                {attendees.map((attendee) => (
                  <li
                    key={attendee.id}
                    className="flex items-center gap-2 mt-3"
                  >
                    <Avatar
                      className="border border-base-black-8"
                      src={undefined}
                      displayName={attendee.name}
                      size="sm"
                    />
                    <p className="text-sm text-gray-900 font-medium truncate">
                      {attendee.name}
                    </p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </aside>
      </section>
    </div>
  );
};
