import {
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { pageCreationFlowModel } from '../';
import {
  ActiveAIStepType,
  PageCreationFlowStepsEnum,
  PageCreationTypesEnum,
} from '../../config/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { foldersModel } from '../../../../features/folders';
import { RT } from '../../../../shared/types';
import { createPage } from './createPage';
import { templatesModel } from '../../../../features/templates';
import { logger } from '../../../../shared/lib';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';

export function* pageCreationFlowWorker() {
  while (true) {
    const {
      payload: { mode, gongCallTranscriptId },
    }: RT<typeof pageCreationFlowModel.actions.openPageCreationFlow> =
      yield take(pageCreationFlowModel.actions.openPageCreationFlow);

    if (gongCallTranscriptId) {
      yield call(handlePreselectedGongTranscript, gongCallTranscriptId);
    }

    if (mode === 'template') {
      yield call(showTemplatesModalFirstFlow);
    } else if (mode === 'tab') {
      yield call(generateTabFlow);
    } else {
      yield call(regularFlow);
    }
  }
}

function* regularFlow() {
  const task = (yield fork(function* () {
    yield call(verifyFolder);

    yield put(pageCreationFlowModel.actions.setPageCreationFlowModalOpen(true));

    yield fork(pageCreationTypeWatcher);

    yield takeEvery(
      pageCreationFlowModel.actions.parseFileTextContent,
      pageCreationFlowModel.sagas.parseFileTextContent
    );

    yield takeEvery(
      pageCreationFlowModel.actions.parseLinkTextContent,
      pageCreationFlowModel.sagas.parseLinkTextContent
    );

    yield takeEvery(
      pageCreationFlowModel.actions.generateOutline,
      pageCreationFlowModel.sagas.generateOutline
    );

    yield takeEvery(
      pageCreationFlowModel.actions.initializePageGenerationWithAI,
      pageCreationFlowModel.sagas.initializePageGenerationWithAI
    );

    yield takeEvery(
      pageCreationFlowModel.actions.createPage,
      createPageWatcher
    );
  })) as Task;

  yield take(pageCreationFlowModel.actions.closePageCreationFlow);

  yield cancel(task);
  yield call(closePageCreationFlow);
}

function* showTemplatesModalFirstFlow() {
  const task = (yield fork(function* () {
    yield put(
      pageCreationFlowModel.actions.setCurrentStep(
        PageCreationFlowStepsEnum.TEMPLATES
      )
    );
    yield put(pageCreationFlowModel.actions.setPageCreationFlowModalOpen(true));

    yield take(pageCreationFlowModel.actions.setCurrentStep);

    yield call(verifyFolder);

    yield put(
      pageCreationFlowModel.actions.setCurrentStep(
        PageCreationFlowStepsEnum.CREATE_PAGE
      )
    );

    yield takeEvery(
      pageCreationFlowModel.actions.createPage,
      createPageWatcher
    );
  })) as Task;

  yield take(pageCreationFlowModel.actions.closePageCreationFlow);

  yield cancel(task);
  yield call(closePageCreationFlow);
}

function* verifyFolder() {
  const currentFolder: RT<typeof foldersModel.selectors.selectCurrentFolder> =
    yield select(foldersModel.selectors.selectCurrentFolder);

  if (!currentFolder) {
    yield put(pageCreationFlowModel.actions.setChooseFolderModalOpen(true));

    const action: RT<
      | typeof pageCreationFlowModel.actions.setChosenFolderData
      | typeof pageCreationFlowModel.actions.closeChooseFolderModal
    > = yield take([
      pageCreationFlowModel.actions.setChosenFolderData,
      pageCreationFlowModel.actions.closeChooseFolderModal,
    ]);

    if (
      action.type === pageCreationFlowModel.actions.closeChooseFolderModal.type
    ) {
      yield put(pageCreationFlowModel.actions.closePageCreationFlow());
    }
  }
  yield put(pageCreationFlowModel.actions.setChooseFolderModalOpen(false));
}

function* pageCreationTypeWatcher() {
  const { payload: pageCreationType }: PayloadAction<PageCreationTypesEnum> =
    yield take(pageCreationFlowModel.actions.setPageCreationType);

  if (
    pageCreationType === PageCreationTypesEnum.FROM_SCRATCH ||
    pageCreationType === PageCreationTypesEnum.WITH_AI
  ) {
    yield put(
      pageCreationFlowModel.actions.setCurrentStep(
        PageCreationFlowStepsEnum.CREATE_PAGE
      )
    );
  }
  if (pageCreationType === PageCreationTypesEnum.FROM_TEMPLATE) {
    yield put(
      pageCreationFlowModel.actions.setCurrentStep(
        PageCreationFlowStepsEnum.TEMPLATES
      )
    );
  }
}

function* createPageWatcher({
  payload,
}: RT<typeof pageCreationFlowModel.actions.createPage>) {
  const currentFolder: RT<typeof foldersModel.selectors.selectCurrentFolder> =
    yield select(foldersModel.selectors.selectCurrentFolder);

  const chosenFolderData: RT<
    typeof pageCreationFlowModel.selectors.selectChosenFolderData
  > = yield select(pageCreationFlowModel.selectors.selectChosenFolderData);

  if (!currentFolder && !chosenFolderData) {
    const error = 'Folder is not defined';
    logger.error(error);
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', error)
      )
    );
  }

  yield call(createPage, {
    ...payload,
    folderId: currentFolder
      ? currentFolder.id
      : chosenFolderData?.folderId ?? '',
    folderName: currentFolder
      ? currentFolder.title
      : chosenFolderData?.folderName ?? '',
  });

  yield put(pageCreationFlowModel.actions.closePageCreationFlow());
}

function* generateTabFlow() {
  const task = (yield fork(function* () {
    yield put(
      pageCreationFlowModel.actions.setPageCreationType(
        PageCreationTypesEnum.TAB
      )
    );
    yield put(
      pageCreationFlowModel.actions.setCurrentStep(
        PageCreationFlowStepsEnum.CREATE_PAGE
      )
    );
    yield put(
      pageCreationFlowModel.actions.setActiveAIStep(ActiveAIStepType.TYPE)
    );
    yield put(pageCreationFlowModel.actions.setPageCreationFlowModalOpen(true));

    yield takeEvery(
      pageCreationFlowModel.actions.generateOutline,
      pageCreationFlowModel.sagas.generateOutline
    );

    yield takeEvery(
      pageCreationFlowModel.actions.generateTab,
      pageCreationFlowModel.sagas.generateTab
    );
  })) as Task;

  yield take(pageCreationFlowModel.actions.closePageCreationFlow);

  yield cancel(task);
  yield call(closePageCreationFlow);
}

function* closePageCreationFlow() {
  yield put(pageCreationFlowModel.actions.setPageCreationFlowModalOpen(false));
  yield put(pageCreationFlowModel.actions.setChooseFolderModalOpen(false));
  yield put(
    pageCreationFlowModel.actions.setCurrentStep(
      PageCreationFlowStepsEnum.GET_STARTED
    )
  );
  yield put(templatesModel.actions.setCurrentSelectedTemplate(undefined));
  yield put(templatesModel.actions.setPreviewMode(false));
  yield put(pageCreationFlowModel.actions.reset());
}

function* handlePreselectedGongTranscript(gongCallTranscriptId: string) {
  yield put(
    pageCreationFlowModel.actions.setPageCreationType(
      PageCreationTypesEnum.WITH_AI
    )
  );
  yield put(
    pageCreationFlowModel.actions.setCurrentStep(
      PageCreationFlowStepsEnum.CREATE_PAGE
    )
  );
  yield put(
    pageCreationFlowModel.actions.setActiveAIStep(ActiveAIStepType.SOURCE)
  );
  yield put(pageCreationFlowModel.actions.setPageCreationFlowModalOpen(true));

  yield put(
    pageCreationFlowModel.actions.setPreselectedGongCallTranscriptId(
      gongCallTranscriptId
    )
  );
}

export * from './parseFileTextContent';
export * from './parseLinkTextContent';
export * from './generateOutline';
export * from './initializePageGenerationWithAI';
export * from './generateTab';
