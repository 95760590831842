import { IconMap } from '../../shared/sprite';

export const MeetingPlatforms = {
  googleMeet: {
    name: 'On Google Meet',
    icon: IconMap.GoogleMeet,
  },
  zoom: {
    name: 'on Zoom',
    icon: IconMap.ZoomCall,
  },
  teams: {
    name: 'on Teams',
    icon: IconMap.TeamsCall,
  },
} as const;
