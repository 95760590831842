import { MouseEvent, useState } from 'react';
import { CallTranscriptsPicker } from './CallTranscriptsPicker';
import { GongCallerNameSelect } from './GongCallerNameSelect';
import { LinkElement } from './LinkElement';
import { FileElement } from './FileElement';
import { DataSourceSelect, DataSourceItem } from './DataSourceSelect';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { gongModel } from '../../../../../../../entities/gong';
import {
  teamsModel,
  useTeamPermissions,
} from '../../../../../../../features/teams';
import { Trigger } from '../Trigger';
import { transcriptsFilterList } from './config';
import { IconMap } from '../../../../../../../shared/sprite';
import {
  FileInputRedesigned,
  Icon,
  Input,
  Select,
  Button,
  Toggle,
  Tooltip,
} from '../../../../../../../shared/ui';
import {
  redirectActions,
  TEAM_SETTINGS_INFO_ROUTE,
} from '../../../../../../../entities/history';
import { useEffect, useMemo, useRef } from 'react';
import { LINK_VERIFICATION_REGEX } from '../../../../../../../shared/constants';
import {
  createNotification,
  snackbarModel,
} from '../../../../../../../features/snackbar';
import { cn } from '@distribute/frontend/utils';
import { FormattedGongTranscript } from '@distribute/shared/api-types/gong';
import { DOC_FILE_FORMAT } from '@distribute/shared/types';
import { PDF_FILE_FORMAT } from '@distribute/shared/types';
import { TXT_FILE_FORMAT } from '@distribute/shared/types';
import { DOCX_FILE_FORMAT } from '@distribute/shared/types';
import {
  CreatePageFormSchemaType,
  LinkTypeForAIStatusEnum,
  PageCreationDataSourcesEnum,
  TranscriptsFilterTypesEnum,
} from '../../../../../../../processes/page-creation-flow/config/types';
import { pageCreationFlowModel } from '../../../../../../../processes/page-creation-flow/model';

export const SourceTrigger = ({
  isOpened,
  isDone,
}: {
  isOpened: boolean;
  isDone: boolean;
}) => {
  const { watch } = useFormContext<CreatePageFormSchemaType>();

  const selectedDataSource = watch('selectedDataSource');
  const selectedCallerName = watch('selectedCallerName');

  const isGong = selectedDataSource === PageCreationDataSourcesEnum.GONG;

  return (
    <Trigger
      title="Knowledge source"
      isDone={isDone}
      description={
        isDone
          ? [selectedDataSource, isGong ? selectedCallerName ?? '' : ''].filter(
              Boolean
            )
          : undefined
      }
      isOpened={isOpened}
    />
  );
};

type Props = {
  onNext: () => void;
  isDone: boolean;
  isGeneratingNewTab?: boolean;
};
export const Source = ({ onNext, isDone, isGeneratingNewTab }: Props) => {
  const dispatch = useDispatch();

  const currentTeam = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const isPersonalGongAccountConnected = useSelector(
    gongModel.selectors.selectIsConnected
  );

  const isTeamGongAccountConnected = currentTeam.isGongConnected;

  const isGongConnected =
    isPersonalGongAccountConnected || isTeamGongAccountConnected;

  const gongCallTranscripts = useSelector(
    gongModel.selectors.selectCallTranscripts
  );

  const isLoadingGongTranscripts = useSelector(
    gongModel.selectors.selectIsLoadingTranscripts
  );

  const preselectedGongCallTranscriptId = useSelector(
    pageCreationFlowModel.selectors.selectPreselectedGongCallTranscriptId
  );

  const teamPermissions = useTeamPermissions();

  const {
    control,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useFormContext<CreatePageFormSchemaType>();

  const {
    field: { value: selectedCallerName, onChange: setSelectedCallerName },
  } = useController({
    control,
    name: 'selectedCallerName',
    rules: { required: true },
  });

  const {
    field: { value: selectedDataSource, onChange: setSelectedDataSource },
  } = useController({
    control,
    name: 'selectedDataSource',
    rules: { required: true },
  });

  const {
    field: {
      value: selectedTranscriptsFilter,
      onChange: handleChangeTranscriptsFilter,
    },
  } = useController({
    control,
    name: 'transcriptsFilter',
    rules: { required: true },
  });

  const {
    field: { value: selectedTranscripts, onChange: setSelectedTranscripts },
  } = useController({
    control,
    name: 'selectedTranscripts',
    rules: { required: true },
  });

  const {
    field: { value: textValue, onChange: setTextValue },
  } = useController({
    control,
    name: 'text',
    rules: { required: true },
  });

  const {
    fields: links,
    append: appendLink,
    remove: removeLink,
    update: updateLink,
  } = useFieldArray({
    control,
    name: 'links',
  });

  const {
    fields: files,
    append: appendFile,
    remove: removeFile,
  } = useFieldArray({
    control,
    name: 'files',
  });

  const {
    field: { value: isUsingTabsAsAContext, onChange: setIsUsingTabsAsAContext },
  } = useController({
    control,
    name: 'isUsingTabsAsAContext',
  });

  const filteredTranscriptsByName = useMemo(
    () =>
      gongCallTranscripts?.filter(
        (el) => !!el.parties.find((el) => el.name === selectedCallerName)
      ),
    [gongCallTranscripts, selectedCallerName]
  );

  const sourceSelectRef = useRef<HTMLButtonElement>(null);

  const handleGongSelect = () => {
    setSelectedDataSource(PageCreationDataSourcesEnum.GONG);
    sourceSelectRef.current?.click();
  };

  const connectToGong = () => {
    dispatch(
      redirectActions.fromWorkspaceWithBackUrl({
        path: TEAM_SETTINGS_INFO_ROUTE,
      })
    );
  };

  const handleGongClick = (e: MouseEvent) => {
    if (isGongConnected) {
      handleGongSelect();
    } else {
      e.stopPropagation();
      connectToGong();
    }
  };

  const toggleCallTranscript = (transcriptId: string) => {
    const index = selectedTranscripts.indexOf(transcriptId);
    if (index !== -1) {
      setSelectedTranscripts(
        selectedTranscripts.filter((id) => id !== transcriptId)
      );
    } else {
      setSelectedTranscripts([...selectedTranscripts, transcriptId]);
    }
  };

  const toggleAllTranscripts = () => {
    if (selectedTranscripts.length === filteredTranscriptsByName?.length) {
      setSelectedTranscripts([]);
    } else {
      setSelectedTranscripts(
        filteredTranscriptsByName?.map((el) => el.metaData.id)
      );
    }
  };

  const dataSources: DataSourceItem[] = [
    {
      id: PageCreationDataSourcesEnum.TEXT,
      title: 'Input text',
      iconName: IconMap.TextLines,
    },
    {
      id: PageCreationDataSourcesEnum.FILE,
      title: 'Upload file(s)',
      iconName: IconMap.UploadCloud02,
    },
    {
      id: PageCreationDataSourcesEnum.LINK,
      title: 'Add a link',
      iconName: IconMap.Link,
    },
    {
      id: PageCreationDataSourcesEnum.GONG,
      title: 'Gong',
      isIntegration: true,
      iconImageSrc: '../../../assets/images/gong.svg',
      isConnected: isGongConnected,
      handleClick: handleGongClick,
      hasConnectBtn: teamPermissions.isCanConnectIntegrations,
    },
  ];

  const [filesLeftToParse, setFilesLeftToParse] = useState(0);
  const [areFilesUploading, setFilesUploading] = useState(false);

  useEffect(() => {
    if (filesLeftToParse === 0) {
      setFilesUploading(false);
    }
  }, [filesLeftToParse]);

  const handleFilesUpload = (files: File[]) => {
    files.forEach((file) => {
      dispatch(
        pageCreationFlowModel.actions.parseFileTextContent({
          file,
          cb: (data: string | null) => {
            if (data) {
              appendFile({
                name: file.name,
                size: file.size,
                type: file.type,
                data,
              });
            }
            setFilesLeftToParse((prev) => prev - 1);
          },
        })
      );
    });
  };

  const handleParseLink = (link: string, index: number) => {
    if (!link.match(LINK_VERIFICATION_REGEX)) return;

    updateLink(index, {
      url: link,
      data: null,
      status: LinkTypeForAIStatusEnum.PARSING,
    });

    dispatch(
      pageCreationFlowModel.actions.parseLinkTextContent({
        link,
        cb: (data: string | null) => {
          if (data !== null) {
            updateLink(index, {
              url: link,
              data,
              status: LinkTypeForAIStatusEnum.PARSED,
            });
            return;
          }
          updateLink(index, {
            url: link,
            data: null,
            status: LinkTypeForAIStatusEnum.ERROR,
          });
        },
      })
    );
  };

  useEffect(() => {
    if (isGongConnected && !gongCallTranscripts) {
      dispatch(gongModel.actions.getCallTranscripts());
    }
  }, [dispatch, gongCallTranscripts, isGongConnected]);

  useEffect(() => {
    if (preselectedGongCallTranscriptId && gongCallTranscripts?.length) {
      const currentCallTranscript = gongCallTranscripts.find(
        (item) => item.metaData.id === preselectedGongCallTranscriptId
      );
      if (!currentCallTranscript) {
        dispatch(
          snackbarModel.actions.addNotificationAction(
            createNotification('error', `Call transcript is not found`)
          )
        );
        return;
      }
      setValue('selectedCallerName', currentCallTranscript?.parties[0].name);
    }
  }, [
    dispatch,
    gongCallTranscripts,
    gongCallTranscripts?.length,
    preselectedGongCallTranscriptId,
    setValue,
  ]);

  const handlePageGeneration = () => {
    return;
  };

  return (
    <div className="relative h-full">
      <form
        onSubmit={handleSubmit(handlePageGeneration)}
        className={cn('h-full')}
      >
        <div className="flex flex-col justify-center w-full h-full">
          <DataSourceSelect
            items={dataSources}
            onChange={setSelectedDataSource}
            value={selectedDataSource || ''}
            currentValue={dataSources.find(
              (el) => el.id === selectedDataSource
            )}
            ref={sourceSelectRef}
            isError={!!errors.selectedDataSource}
            messageText={errors.selectedDataSource?.message}
            isLoading={
              isLoadingGongTranscripts &&
              selectedDataSource === PageCreationDataSourcesEnum.GONG
            }
          />

          {selectedDataSource === PageCreationDataSourcesEnum.GONG &&
            gongCallTranscripts && (
              <GongCallerNameSelect
                handleSelect={setSelectedCallerName}
                isError={!!errors.selectedCallerName}
              />
            )}
          {selectedDataSource === PageCreationDataSourcesEnum.GONG && (
            <>
              {selectedCallerName && (
                <div className="flex items-start gap-3 whitespace-nowrap my-3">
                  <p className="text-gray-700 mt-2.5">Call transcript(s)</p>
                  <div className="w-full">
                    <Select
                      label=""
                      onChange={handleChangeTranscriptsFilter}
                      items={transcriptsFilterList}
                      value={selectedTranscriptsFilter}
                      currentValue={transcriptsFilterList.find(
                        (type) => selectedTranscriptsFilter === type.id
                      )}
                      error={errors.transcriptsFilter?.message}
                      errorMsg={errors.transcriptsFilter?.message}
                      listStyles="max-h-60 !gap-y-0.5 !p-1.5"
                      listItemStyles="h-9.5 pl-2.5"
                      className="!h-11"
                      buttonStyles="!h-11"
                      hideLabel
                    />
                  </div>
                </div>
              )}
              {filteredTranscriptsByName &&
                ((selectedCallerName &&
                  selectedTranscriptsFilter ===
                    TranscriptsFilterTypesEnum.SPECIFIC) ||
                  (preselectedGongCallTranscriptId && gongCallTranscripts)) && (
                  <CallTranscriptsPicker
                    transcripts={
                      preselectedGongCallTranscriptId
                        ? (gongCallTranscripts as FormattedGongTranscript[])
                        : filteredTranscriptsByName
                    }
                    selectedTranscripts={selectedTranscripts}
                    toggleCallTranscript={toggleCallTranscript}
                    toggleAllTranscripts={toggleAllTranscripts}
                    isError={!!errors.selectedTranscripts}
                    messageText={errors.selectedTranscripts?.message}
                  />
                )}
            </>
          )}
          {selectedDataSource === PageCreationDataSourcesEnum.TEXT && (
            <Input
              type="text"
              isTextArea
              textAreaRows={5}
              counterValue={textValue.length}
              isCounterOnly
              isCounterInside
              value={textValue}
              onChange={setTextValue}
              placeholder="Provide all available information regarding your page's topic by typing it or copy pasting it from your source."
              isError={!!errors.text}
              messageText={errors.text?.message}
            />
          )}
          {selectedDataSource === PageCreationDataSourcesEnum.LINK && (
            <div>
              {links.map((link, index, arr) => (
                <LinkElement
                  index={index}
                  isAddMore={index === arr.length - 1 && arr.length < 5}
                  isDelete={arr.length > 1}
                  handleAddMore={appendLink}
                  handleDelete={removeLink}
                  handleChange={({ target: { value } }) => {
                    setValue(`links.${index}.url`, value);
                    setValue(
                      `links.${index}.status`,
                      LinkTypeForAIStatusEnum.NOT_PARSED_YET
                    );
                    setValue(`links.${index}.data`, null);
                    clearErrors(`links.${index}`);
                  }}
                  key={link.id}
                  watch={watch}
                  isError={!!errors.links?.[index]}
                  messageText={
                    errors.links?.[index]?.url?.message ||
                    errors.links?.[index]?.status?.message
                  }
                  handleParse={handleParseLink}
                />
              ))}
            </div>
          )}
          {selectedDataSource === PageCreationDataSourcesEnum.FILE && (
            <>
              {files.length < 5 && (
                <FileInputRedesigned
                  onUpload={handleFilesUpload}
                  isError={!!errors.files}
                  isMultiple
                  isUploading={areFilesUploading}
                  selectFilesAmount={(size) => {
                    setFilesUploading(true);
                    setFilesLeftToParse(size);
                  }}
                  allowedTypes={[
                    TXT_FILE_FORMAT,
                    DOCX_FILE_FORMAT,
                    PDF_FILE_FORMAT,
                    DOC_FILE_FORMAT,
                  ]}
                />
              )}
              {errors.files?.message && (
                <p className="mt-1.5 !text-error-500 text-sm">
                  {errors.files?.message}
                </p>
              )}
              {files.map((file, index) => (
                <FileElement
                  file={file}
                  key={file.id}
                  handleDelete={() => {
                    removeFile(index);
                    setTimeout(() => clearErrors('files'), 0);
                  }}
                />
              ))}
            </>
          )}
        </div>
        <div
          className={cn('flex items-center justify-end mt-5', {
            'justify-between': isGeneratingNewTab,
          })}
        >
          {isGeneratingNewTab && (
            <div className="flex gap-2">
              <Toggle
                checked={isUsingTabsAsAContext}
                onChange={() =>
                  setIsUsingTabsAsAContext(!isUsingTabsAsAContext)
                }
              />
              <p className="text-sm font-normal text-gray-600">
                Use existing tabs as a context
              </p>
            </div>
          )}
          <Tooltip
            className={cn({ hidden: isDone })}
            trigger={
              <Button
                disabled={!isDone}
                onClick={onNext}
                variant="text"
                color="primary"
                size="md"
              >
                Next <Icon glyph={IconMap.ArrowRight} width={24} />
              </Button>
            }
            align="end"
            sideOffset={4}
          >
            <p className="px-2 py-1 font-semibold text-xs w-44">
              {selectedDataSource === PageCreationDataSourcesEnum.TEXT &&
                'Please provide information related to the page topic.'}
              {selectedDataSource === PageCreationDataSourcesEnum.FILE &&
                'Please upload file(s) related to the page topic.'}
              {selectedDataSource === PageCreationDataSourcesEnum.LINK &&
                'Please add link(s) related to the page topic.'}
              {selectedDataSource === PageCreationDataSourcesEnum.GONG &&
                'Please select the appropriate transcription(s).'}
            </p>
          </Tooltip>
        </div>
      </form>
    </div>
  );
};
