import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PageItem } from './components/PageItem';
import { EmptyPage } from './components/EmptyPageContent';
import { Dropdown, Icon, Input } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { getFilteredPages, getSortedPages } from '../lib';
import { Page } from '@distribute/shared/types';
import {
  DeleteFolderModal,
  foldersModel,
  RenameFolderModal,
} from '../../../features/folders';
import {
  DeletePageModal,
  pagesModel,
  MoveToFolderModal,
  CreatePageButtons,
} from '../../../features/pages';
import { useDispatch, useSelector } from 'react-redux';
import { ShareFolderModal } from '../../../features/folders';
import { useFolderPermissions } from '../../../features/folders';
import { teamsModel, useTeamPermissions } from '../../../features/teams';
import { STORAGE_KEY_WORKSPACE_PAGES_FILTER } from '../config';
import {
  MobileWalkthroughModal,
  workspaceModel,
} from '../../../features/workspace';
import { conversionKitModel } from '../../../features/conversion-kit';
import { SharePageModal } from '../../editor/ui/components/share-page-modal';
import { useWindowWidth } from '@distribute/frontend/utils';
import { useUserAgentDevices } from '../../../shared/hooks/useUserAgentDevices';
import { PageCreationFlow } from '../../../processes/page-creation-flow';

export enum FilterState {
  All = 'all',
  Published = 'published',
  Drafts = 'drafts',
}

export const WorkspacePage: React.FC = () => {
  const dispatch = useDispatch();
  const currentPageFromStore = useSelector(
    pagesModel.selectors.selectCurrentPage
  );
  const handleChangePagesFilter = (id: string) => {
    localStorage.setItem(STORAGE_KEY_WORKSPACE_PAGES_FILTER, id);
    setCurrentSortDropdownItemId(id);
  };
  const mockSortDropdownItems = useMemo(
    () => [
      {
        label: 'Last Modified',
        onClick: () => {
          handleChangePagesFilter('last-modified');
        },
        id: 'last-modified',
      },
      {
        label: 'Last Created',
        onClick: () => {
          handleChangePagesFilter('last-created');
        },
        id: 'last-created',
      },
      {
        label: 'Name',
        onClick: () => {
          handleChangePagesFilter('name');
        },
        id: 'name',
      },
      {
        label: 'Most Viewed',
        onClick: () => {
          handleChangePagesFilter('most-viewed');
        },
        id: 'most-viewed',
      },
    ],
    []
  );

  const mockFilterDropdownItems = useMemo(
    () => [
      {
        label: 'All',
        onClick: () => {
          setCurrentFilter(FilterState.All);
        },
        id: FilterState.All,
      },
      {
        label: 'Published',
        onClick: () => {
          setCurrentFilter(FilterState.Published);
        },
        id: FilterState.Published,
      },
      {
        label: 'Not Published',
        onClick: () => {
          setCurrentFilter(FilterState.Drafts);
        },
        id: FilterState.Drafts,
      },
    ],
    []
  );

  const [currentFilter, setCurrentFilter] = useState<string>(
    mockFilterDropdownItems[0].id
  );

  const [currentSortDropdownItemId, setCurrentSortDropdownItemId] =
    useState<string>(
      localStorage.getItem(STORAGE_KEY_WORKSPACE_PAGES_FILTER) ??
        mockSortDropdownItems[3].id
    );

  const currentFilterName = useMemo(
    () => mockFilterDropdownItems.find((f) => f.id === currentFilter)?.label,
    [mockFilterDropdownItems, currentFilter]
  );

  const currentSortName = useMemo(
    () =>
      mockSortDropdownItems.find((f) => f.id === currentSortDropdownItemId)
        ?.label,
    [mockSortDropdownItems, currentSortDropdownItemId]
  );

  const pages = useSelector(pagesModel.selectors.selectPages);

  const folders = useSelector(foldersModel.selectors.selectFolders);
  const currentFolder = useSelector(foldersModel.selectors.selectCurrentFolder);
  const isAllFolders = !!currentFolder;

  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleClear = () => {
    setSearchQuery('');
  };

  const handleSearchQueryChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };

  const folderPages = useMemo(() => {
    return currentFolder
      ? pages.filter((page) => page.folderId === currentFolder?.id)
      : pages;
  }, [currentFolder, pages]);

  const currentPagesToShow = useMemo(() => {
    const currentSortCondition = mockSortDropdownItems.find(
      (i) => i.id === currentSortDropdownItemId
    )?.label;

    const filteredMockPages = getFilteredPages(folderPages, currentFilter);
    const sortedMockPages = getSortedPages(
      filteredMockPages,
      currentSortCondition
    );

    return sortedMockPages.filter((page) =>
      new RegExp(searchQuery, 'ig').test(page.content?.title || '')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter, searchQuery, currentSortDropdownItemId, folderPages]);

  // TODO: try to move this features into separate components
  const [currentPage, setCurrentPage] = useState<Page | null>(null);

  const [isDeletePageModalOpen, setIsDeletePageModalOpen] = useState(false);
  const [isMovePageToFolderModalOpen, setIsMovePageToFolderModalOpen] =
    useState(false);

  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [isRenameFolderModalOpen, setIsRenameFolderModalOpen] = useState(false);
  const [isShareFolderModalOpen, setIsShareFolderModalOpen] = useState(false);
  const [isSharePageModalOpen, setIsSharePageModalOpen] = useState(false);
  const folderPermissions = useFolderPermissions(currentFolder);

  const teamUsers = useSelector(teamsModel.selectors.selectCurrentTeamMembers);
  const teamUsersInviteAccepted = useMemo(
    () => teamUsers.filter((user) => !user.isWaitingForInviteAcceptance),
    [teamUsers]
  );

  const folderOptions = useMemo(
    () =>
      [
        {
          id: 'Share',
          label: 'Share',
          onClick: () => setIsShareFolderModalOpen(true),
          iconName: IconMap.UserPlus,
          iconWidth: 16,
          isShow:
            teamUsersInviteAccepted?.length > 1 &&
            folderPermissions.isCanManageAccess,
        },
        {
          id: 'Rename',
          label: 'Rename',
          onClick: () => setIsRenameFolderModalOpen(true),
          iconName: IconMap.MenuEdit,
          iconWidth: 16,
          isSeparatedFromTop: teamUsersInviteAccepted?.length > 1,
          isShow: folderPermissions.isCanRename,
        },
        {
          id: 'Delete',
          label: 'Delete',
          onClick: () => setIsDeleteFolderModalOpen(true),
          iconName: IconMap.Delete,
          iconWidth: 16,
          isShow: folderPermissions.isCanDelete,
        },
      ].filter((i) => i.isShow),
    [
      folderPermissions.isCanDelete,
      folderPermissions.isCanRename,
      folderPermissions.isCanManageAccess,
      teamUsersInviteAccepted,
    ]
  );

  const onDeletePageOpen = (id: string) => {
    setCurrentPage(pages.find((p) => p.id === id) as Page);
    setIsDeletePageModalOpen(true);
  };
  const onMoveToFolderOpen = (id: string) => {
    setCurrentPage(pages.find((p) => p.id === id) as Page);
    setIsMovePageToFolderModalOpen(true);
  };

  const handleSharePage = (id: string) => {
    const page = pages.find((p) => p.id === id) as Page;
    if (page) {
      dispatch(
        workspaceModel.actions.setConversionToolsData({
          documentId: page?.content.id,
        })
      );
    }
    dispatch(pagesModel.actions.setCurrentPage(page));
    setIsSharePageModalOpen(true);
  };

  const requireEmailToView = useSelector(
    conversionKitModel.selectors.selectRequireEmailToView
  );
  const alertBar = useSelector(conversionKitModel.selectors.selectAlertBar);
  const popUp = useSelector(conversionKitModel.selectors.selectPopUp);
  const cta = useSelector(conversionKitModel.selectors.selectCTA);
  const gatedContent = useSelector(
    conversionKitModel.selectors.selectGatedContent
  );
  const squeezePage = useSelector(
    conversionKitModel.selectors.selectSqueezePage
  );

  const { isGuest } = useTeamPermissions();

  const isShareModalAvailable =
    currentPageFromStore &&
    alertBar &&
    popUp &&
    squeezePage &&
    gatedContent &&
    cta &&
    requireEmailToView;

  const { isMobile } = useWindowWidth();
  const { isTablet } = useUserAgentDevices();

  const isCreateButtonsVisible =
    folderPermissions.isCanCreatePages && !isMobile && !isTablet;

  return (
    <>
      <Helmet
        titleTemplate={`${
          currentFolder ? currentFolder.title : 'Home'
        } - Distribute`}
      ></Helmet>
      <PageCreationFlow />
      {currentFolder && (
        <>
          <DeleteFolderModal
            isOpen={isDeleteFolderModalOpen}
            onClose={() => setIsDeleteFolderModalOpen(false)}
            folder={currentFolder}
          />
          <RenameFolderModal
            key={currentFolder.id}
            isOpen={isRenameFolderModalOpen}
            onClose={() => setIsRenameFolderModalOpen(false)}
            folder={currentFolder}
          />
          <ShareFolderModal
            isOpen={isShareFolderModalOpen}
            onClose={() => setIsShareFolderModalOpen(false)}
            folder={currentFolder}
          />
        </>
      )}
      {currentPage && (
        <>
          <DeletePageModal
            isOpen={isDeletePageModalOpen}
            onClose={() => {
              setCurrentPage(null);
              setIsDeletePageModalOpen(false);
            }}
            page={currentPage}
          />
          <MoveToFolderModal
            isOpen={isMovePageToFolderModalOpen}
            onClose={() => {
              setCurrentPage(null);
              setIsMovePageToFolderModalOpen(false);
            }}
            page={currentPage}
          />
        </>
      )}
      {isShareModalAvailable && (
        <SharePageModal
          isOpen={isSharePageModalOpen}
          isFolderPage
          onClose={() => {
            setIsSharePageModalOpen(false);
            dispatch(pagesModel.actions.setCurrentPage());
          }}
        />
      )}
      <div className="flex flex-col flex-1 mb-8 bg-base-white">
        {isGuest && !!currentPagesToShow.length && (
          <p className="font-medium text-gray-900 truncate text-display-sm font-display">
            {folders.find(
              (folder) => folder.id === currentPagesToShow[0].folderId
            )?.title ?? 'All pages'}
          </p>
        )}
        {!isGuest && (
          <div className="flex flex-col gap-6 md:flex md:flex-col md:gap-y-6">
            <div className="flex items-center justify-between">
              <p className="font-medium text-gray-900 truncate text-display-sm font-display">
                {currentFolder ? currentFolder?.title : 'All Pages'}
              </p>
              <div>{isCreateButtonsVisible && <CreatePageButtons />}</div>
            </div>
            <div className="flex items-center justify-between gap-1 max1140:flex-col max1140:items-start md:flex-row md:items-center sm:flex-col sm:items-start max1140:gap-4">
              {!!folderPages.length && (
                <div className="flex items-center gap-4">
                  <div className="relative">
                    <Icon
                      glyph={IconMap.SearchLg}
                      width={20}
                      className="absolute left-3.5 top-2.5 z-10 text-gray-500"
                    />
                    <Input
                      type="text"
                      placeholder="Search"
                      className="!w-80 !h-10 !pl-10.5 !pr-8.5 !py-2.5 !text-md !text-gray-900 font-medium placeholder:font-normal shadow-xs"
                      value={searchQuery}
                      onChange={handleSearchQueryChange}
                      id="search"
                    />
                    {searchQuery && (
                      <Icon
                        glyph={IconMap.XCircle}
                        width={20}
                        className="absolute right-3.5 top-2.5 z-10 text-gray-700 cursor-pointer"
                        onClick={handleClear}
                      />
                    )}
                  </div>
                  <div className="text-sm font-normal text-gray-700">
                    {currentPagesToShow.length}{' '}
                    {currentPagesToShow.length === 1 ? 'page' : 'pages'}
                  </div>
                </div>
              )}
              <div className="flex flex-row col-auto gap-3 ml-auto md:ml-0 max1140:w-full md:w-auto sm:w-full">
                {!!folderPages.length && (
                  <div className="w-40 md:hidden hover:bg-gray-50">
                    <Dropdown
                      listStyles="shadow-lg"
                      items={mockFilterDropdownItems}
                      currentItemId={currentFilter}
                      itemStyles="w-40 sm:min-w-full"
                      triggerStyles="w-40 sm:min-w-full"
                    />
                  </div>
                )}
                {!!folderPages.length && (
                  <div className="w-40 md:hidden hover:bg-gray-50">
                    <Dropdown
                      listStyles="shadow-lg"
                      items={mockSortDropdownItems}
                      currentItemId={currentSortDropdownItemId}
                      itemStyles="w-40 sm:min-w-full"
                      triggerStyles="w-40 sm:min-w-full"
                    />
                  </div>
                )}
                <div className="hidden md:flex flex-row gap-x-0">
                  <Dropdown
                    listStyles="shadow-lg"
                    triggerComponent={
                      <DropdownMenu.Trigger className="focus:outline-none">
                        <span className="border-gray-300 flex items-center justify-center border bg-base-white hover:bg-gray-50 p-2.25 rounded-l-lg md:px-4 md:rounded-lg md:mr-3">
                          <Icon
                            glyph={IconMap.FilterFunnel02}
                            width={20}
                            className="text-gray-700 md:hidden"
                          />
                          <span className="hidden md:block text-sm font-semibold text-gray-700 mr-2">
                            {currentFilterName}
                          </span>
                          <Icon
                            glyph={IconMap.ArrowDown}
                            width={20}
                            className="text-gray-700 md:block hidden"
                          />
                        </span>
                      </DropdownMenu.Trigger>
                    }
                    items={mockFilterDropdownItems}
                  />
                  <Dropdown
                    listStyles="shadow-lg"
                    triggerComponent={
                      <DropdownMenu.Trigger className="focus:outline-none">
                        <span className="border-gray-300 flex items-center justify-center border-y border-r bg-base-white hover:bg-gray-50 p-2.25 rounded-r-lg md:px-4 md:rounded-lg md:border">
                          <Icon
                            glyph={IconMap.SwitchVertical01}
                            width={20}
                            className="text-gray-700 md:hidden"
                          />
                          <span className="hidden md:block text-sm font-semibold text-gray-700 mr-2">
                            {currentSortName}
                          </span>
                          <Icon
                            glyph={IconMap.ArrowDown}
                            width={20}
                            className="text-gray-700 md:block hidden"
                          />
                        </span>
                      </DropdownMenu.Trigger>
                    }
                    items={mockSortDropdownItems}
                  />
                </div>
                {isAllFolders && folderOptions.length > 0 && (
                  <Dropdown
                    listStyles="shadow-lg"
                    triggerComponent={
                      <DropdownMenu.Trigger className="focus:outline-none">
                        <span className="border-gray-300 flex items-center justify-center border bg-base-white hover:bg-gray-50 p-2.25 rounded-lg">
                          <Icon
                            glyph={IconMap.DotsVertical}
                            width={20}
                            className="text-gray-700"
                          />
                        </span>
                      </DropdownMenu.Trigger>
                    }
                    items={folderOptions}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {currentPagesToShow.length ? (
          <div className="flex flex-wrap mt-8 -mx-4">
            {currentPagesToShow.map((i) => {
              return (
                <PageItem
                  onSharePage={handleSharePage}
                  onDelete={onDeletePageOpen}
                  onMoveToFolder={onMoveToFolderOpen}
                  key={i.id}
                  page={i}
                />
              );
            })}
          </div>
        ) : (
          <EmptyPage currentFilter={currentFilter} />
        )}
      </div>
      {(isMobile || isTablet) && <MobileWalkthroughModal />}
    </>
  );
};
