import { call, delay, put, select } from 'redux-saga/effects';
import { pageCreationFlowModel } from '..';
import { authUserModel } from '../../../../entities/auth-user';
import { teamsModel } from '../../../../features/teams';
import { gongModel } from '../../../../entities/gong';
import { RT } from '../../../../shared/types';
import { PageCreationDataSourcesEnum } from '../../config/types';
import {
  createNotification,
  snackbarModel,
} from '../../../../features/snackbar';
import { getPageGenerationContext } from '../../config/config';
import { aiApi } from '../../../../shared/api';
import {
  CreateWithAIPageType,
  CreateWithAIRequestType,
  GenerateOutlineResponseType,
} from '@distribute/shared/api-types/create-with-ai';
import { CreateDataEmbeddingResponseType } from '@distribute/shared/api-types/data-embeddings';
import { dataEmbeddingsApi } from '../../../../shared/api/axios/data-embeddings';
import { logger } from '../../../../shared/lib';

export function* generateOutline({
  payload: formData,
}: ReturnType<typeof pageCreationFlowModel.actions.generateOutline>) {
  try {
    yield put(pageCreationFlowModel.actions.setIsGenerateOutlineLoading(true));
    yield put(
      pageCreationFlowModel.actions.setWasOutlineGenerationStarted(true)
    );

    const user: RT<typeof authUserModel.selectors.selectUserWithError> =
      yield select(authUserModel.selectors.selectUserWithError);

    const currentTeam: RT<
      typeof teamsModel.selectors.selectCurrentTeamWithError
    > = yield select(teamsModel.selectors.selectCurrentTeamWithError);

    const gongCallTranscripts: RT<
      typeof gongModel.selectors.selectCallTranscripts
    > = yield select(gongModel.selectors.selectCallTranscripts);

    if (
      formData.selectedDataSource === PageCreationDataSourcesEnum.GONG &&
      !gongCallTranscripts
    ) {
      const error = 'Failed to generate a page. No transcripts were found';

      logger.error(error);
      yield put(
        snackbarModel.actions.addNotificationAction(
          createNotification('error', error)
        )
      );
      return;
    }

    const context = getPageGenerationContext(formData, gongCallTranscripts);

    const isTranscript =
      formData.selectedDataSource === PageCreationDataSourcesEnum.GONG;

    const shouldEmbed = !isTranscript || context.length > 1;

    let embeddingResponse: CreateDataEmbeddingResponseType;
    const requestData: CreateWithAIRequestType = {
      userName: user.displayName,
      companyName: currentTeam.brandCompanyName ?? undefined,
      aboutCompany: currentTeam.brandCompanyInfo ?? undefined,
      pageTitle: formData.pageTitle,
      pageType: formData.pageType as CreateWithAIPageType,
      pageTypeDescription: formData.pageTypeDescription,
      prospectName: formData.selectedCallerName,
      isTranscript,
      hasEmbeddings: shouldEmbed,
      contextRawData: context,
      teamId: currentTeam.id,
      brandCompany: formData.brandCompany?.name ?? undefined,
      existingTabsContext: formData.existingTabsContext,
    };

    yield put(pageCreationFlowModel.actions.setContextRawData(context));

    let generatedOutline: GenerateOutlineResponseType;

    if (shouldEmbed) {
      embeddingResponse = yield call(dataEmbeddingsApi.createEmbedding, {
        context,
        teamId: currentTeam.id,
      });

      const namespace = embeddingResponse.namespace;
      while (true) {
        try {
          const isReady: boolean = yield call(
            dataEmbeddingsApi.getDataEmbeddingStatus,
            namespace
          );

          if (isReady) {
            break;
          }
          yield put(delay(1000));
        } catch (err) {
          break;
        }
      }

      const embeddedContext = {
        id: embeddingResponse.id,
        namespace: embeddingResponse.namespace,
      };

      yield put(
        pageCreationFlowModel.actions.setEmbeddedContext(embeddedContext)
      );

      generatedOutline = yield call(aiApi.generatePageOutline, {
        ...requestData,
        embeddedContext,
      });
    } else {
      yield put(pageCreationFlowModel.actions.setEmbeddedContext(null));

      generatedOutline = yield call(aiApi.generatePageOutline, requestData);
    }

    yield put(
      pageCreationFlowModel.actions.setGeneratedOutline(
        generatedOutline.sections
      )
    );
  } catch (error) {
    logger.error(error);
    yield put(
      pageCreationFlowModel.actions.setWasOutlineGenerationStarted(false)
    );
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to generate page outline')
      )
    );
  } finally {
    yield put(pageCreationFlowModel.actions.setIsGenerateOutlineLoading(false));
  }
}
