import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { Switch } from '@headlessui/react';

import { findObjectChanges } from '@distribute/shared/utils';
import { Section, RadioButton, Button } from '../../../../shared/ui';

import {
  MeetingsVisibility,
  MeetingRecordingConsent,
} from '@distribute/shared/types';

import { useConfirmWindowClose } from '../../../../shared/hooks/useConfirmWindowClose';
import { useTeamPermissions } from '../../../teams';

import { callRecordingSettingsModel } from '../../model';

const teamMeetingVisibilityOptions = [
  {
    id: 'share-all',
    value: MeetingsVisibility.SHARE_ALL,
    label: 'Share all meetings',
  },
  {
    id: 'share-external',
    value: MeetingsVisibility.SHARE_EXTERNAL,
    label: 'Share only external meetings',
  },
  {
    id: 'share-internal',
    value: MeetingsVisibility.SHARE_INTERNAL,
    label: 'Share only internal meetings',
  },
  {
    id: 'share-none',
    value: MeetingsVisibility.SHARE_NONE,
    label: 'Don’t share any meetings',
  },
];

const recordingConsentOptions = [
  {
    id: 'no-notification',
    value: MeetingRecordingConsent.NO_NOTIFICATION,
    label: (
      <span className="text-sm font-medium text-gray-700">
        Don’t send notifications or prompt for consent
      </span>
    ),
  },
  {
    id: 'external-notification',
    value: MeetingRecordingConsent.NOTIFY_EXTERNAL,
    label: (
      <span className="text-sm font-medium text-gray-700">
        Notify participants on external calls only
      </span>
    ),
  },
  {
    id: 'always-notify',
    value: MeetingRecordingConsent.ALWAYS_NOTIFY,
    label: (
      <span className="text-sm font-medium text-gray-700">
        Always notify participants{' '}
        <span className="text-gray-500">(recommended)</span>
      </span>
    ),
  },
];

export const TeamSettingsForm = () => {
  const dispatch = useDispatch();

  const teamSettings = useSelector(
    callRecordingSettingsModel.selectors
      .selectTeamCallRecordingSettingsWithError
  );
  const isTeamSettingsLoading = useSelector(
    callRecordingSettingsModel.selectors.selectIsTeamCallSettingsLoading
  );
  const [meetingsVisibility, setMeetingsVisibility] = useState(
    teamSettings.meetingsVisibility
  );
  const [recordingConsent, setRecordingConsent] = useState(
    teamSettings.recordingConsent
  );
  const [limitToSingleBot, setLimitToSingleBot] = useState(
    teamSettings.limitToSingleBot
  );

  const { isCanEditTeamCallSettings } = useTeamPermissions();

  const { hasChanges, changes } = findObjectChanges(
    {
      meetingsVisibility: teamSettings.meetingsVisibility,
      recordingConsent: teamSettings.recordingConsent,
      limitToSingleBot: teamSettings.limitToSingleBot,
    },
    {
      meetingsVisibility,
      recordingConsent,
      limitToSingleBot,
    }
  );

  const handleSaveSettings = () => {
    dispatch(
      callRecordingSettingsModel.actions.updateTeamCallRecordingSettings(
        changes
      )
    );
  };

  useConfirmWindowClose(hasChanges, 'changeTeamSettings');

  return (
    <Section title="Team settings">
      <div className="flex flex-col gap-8 text-gray-700">
        <div className="flex gap-x-6 items-start">
          <div className="w-64">
            <div className="flex flex-col">
              <p className="text-gray-700 text-sm font-medium">
                Team Meetings Visibility
              </p>
              <p className="text-gray-500 text-xs font-normal">
                Which meetings should be shared on the Team calls tab?
              </p>
            </div>
          </div>
          <div>
            {teamMeetingVisibilityOptions.map((option) => (
              <RadioButton
                key={option.id}
                name="team-meeting-visibility"
                value={option.value}
                onChange={() => setMeetingsVisibility(option.value)}
                label={option.label}
                defaultChecked={meetingsVisibility === option.value}
              />
            ))}
          </div>
        </div>
        <div className="flex gap-x-6 items-start">
          <div className="w-64">
            <div className="flex flex-col">
              <p className="text-gray-700 text-sm font-medium">
                Recording Consent
              </p>
              <p className="text-gray-500 text-xs font-normal">
                Manage how participants are notified about meeting recordings
                and prompted for consent.
              </p>
            </div>
          </div>
          <div>
            {recordingConsentOptions.map((option) => (
              <RadioButton
                key={option.id}
                name="recording-consent"
                value={option.value}
                onChange={() => setRecordingConsent(option.value)}
                labelElement={option.label}
                defaultChecked={recordingConsent === option.value}
              />
            ))}
          </div>
        </div>
        <div className="hidden gap-x-6 items-start">
          <div className="w-64">
            <div className="flex flex-col">
              <p className="text-gray-700 text-sm font-medium">
                Limit to a Single Bot
              </p>
              <p className="text-gray-500 text-xs font-normal">
                Prevent multiple co-pilots from joining your team meetings.
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Switch.Group>
              <Switch
                checked={limitToSingleBot}
                onChange={setLimitToSingleBot}
                className={cn(
                  'relative inline-flex h-6 w-12 items-center rounded-full',
                  {
                    'bg-gray-200': !limitToSingleBot,
                    'bg-primary-600': limitToSingleBot,
                  }
                )}
              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={cn(
                    'inline-block h-4 w-4 transform rounded-full bg-base-white transition',
                    {
                      'translate-x-7': limitToSingleBot,
                      'translate-x-1': !limitToSingleBot,
                    }
                  )}
                />
                <span
                  className={cn(
                    'text-xs font-bold inline-block transform transition',
                    {
                      'translate-x-1.5 text-gray-400': !limitToSingleBot,
                      '-translate-x-2.5 text-primary-100': limitToSingleBot,
                    }
                  )}
                >
                  {limitToSingleBot ? 'ON' : 'OFF'}
                </span>
              </Switch>

              <Switch.Label className="text-sm font-medium">
                Only one bot from team can join a meeting
              </Switch.Label>
            </Switch.Group>
          </div>
        </div>
      </div>
      <div className="py-5">
        <Button
          loading={isTeamSettingsLoading}
          onClick={handleSaveSettings}
          disabled={!hasChanges || !isCanEditTeamCallSettings}
          variant="text"
          color="primary"
          className="text-sm"
        >
          Save
        </Button>
      </div>
    </Section>
  );
};
